import { useState, useContext, useEffect } from 'react';
import { Table } from 'antd';
import { DataTableContext } from '../context/DataTableContext';
import { Columns } from '../components/PageData';

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 0;

export interface Props {
  columns: Columns[];
  useGetQuery: any;
  userObjByType?: { userId?: string, instructorId?: string }
}

function useDataTable({ columns, useGetQuery, userObjByType }: Props) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER);
  const [filter, setFilter] = useState({});
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState<null | 'asc' | 'desc'>(null);
  const { search, responseObjName } = useContext(DataTableContext);
  const [columnsState, setColumnsState] = useState(columns);


  const { data, loading, refetch } = useGetQuery({
    variables: { ...userObjByType, page: currentPage + 1, limit: pageSize, sortField, sortOrder, search },

  });
  const hasSelected = selectedRowKeys.length > 0;
 
  const rowSelection = {
    selectedRowKeys,
    onChange: (selected: any) => {
      setSelectedRowKeys(selected);
    },
  };

  useEffect(() => {
    const updatedColumns = [
      {
        title: 'No',
        dataIndex: 'key',
        key: 'key',
        width: 100,
        render: (text: any, record: any, index: any) => {
          return (currentPage * pageSize + index) + 1
        },
      },
      ...columnsState,

    ];
    setColumnsState(updatedColumns);
  }, []);

  const resetPagination = () => {
    setCurrentPage(DEFAULT_PAGE_NUMBER);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter && sorter.field) {
      const resetColumns = columnsState.map(col => {
        if (col.sortOrder) {
          col.sortOrder = undefined;
        }
        if (col.key === sorter.field) {
          col.sortOrder = sorter.order;
        }
        return col;
      });
      const newState = resetColumns;
      setColumnsState(newState);
    }
    setFilter(filters);
    for (const property in filters) {
      const index = columnsState.findIndex(col => col.key === property);
      columnsState[index] = {
        ...columnsState[index],
        filteredValue: filters[property]
      }
      const newState = columnsState;
      setColumnsState(newState);
    }
    setCurrentPage(pagination.current - 1);
    setPageSize(pagination.pageSize);
    setSortField(sorter.field);
    setSortOrder(sortOrderTransform(sorter.order));
  };

  const sortOrderTransform = (order: string) => {
    switch (order) {
      case 'ascend':
        return 'asc';
      case 'descend':
        return 'desc';
      default:
        return null;
    }
  }

  const DataTable = () => (
    <Table
      sortDirections={['ascend', 'descend']}
      loading={loading}
      rowKey={record => record.id}
      // rowSelection={rowSelection}
      columns={columnsState}
      dataSource={data && responseObjName && data[responseObjName].result}
      // onRow={record => {
      //   return {
      //     onClick: () => {
      //       setSelectedRow(record);
      //     },
      //   };
      // }}
      onChange={handleTableChange}
      pagination={{
        showSizeChanger: true,
        pageSize: pageSize,
        current: currentPage + 1,
        total: data && responseObjName && data[responseObjName].total,
        showTotal: (total, range) => {
          return `${range[0]}-${range[1]} of ${total} items`;
        },
      }}
    />
  );

  return {
    DataTable,
    hasSelected,
    selectedRow,
    selectedRowKeys,
    currentPage,
    pageSize,
    resetPagination,
    refetch
  };
}

export default useDataTable;