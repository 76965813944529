import { useState, useRef, useEffect } from 'react';
import { useFileList } from './__generated__/FileList.query';
import { useRemoveFiles } from './__generated__/FileList.mutation';
import { List, Card, Image, Modal, Button, Checkbox, Tooltip } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import LibraryHeader from './LibraryHeader';
import styles from './library.module.css';

export interface Files {
  Key: String;
}

const LibraryComponent = ({modal, onFileSelect}: { modal?: boolean, onFileSelect?: (file?: string) => void }) => {
  
  const [removeMutation, { data: removeData }] = useRemoveFiles();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [checked, setChecked] = useState<Array<string | number | boolean>>([]);
  const [url, setUrl] = useState('');
  const [search, setSearch] = useState('');
  const video = useRef<any>();
  const { data, loading, refetch } = useFileList({
    variables: {
      search
    },
  });

  const showModal = (url: string) => {
    setUrl(url);
    setIsModalVisible(true);
  };

  const stopVideo = () => {
    video.current && video.current.pause();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setUrl('');
    stopVideo();
  };
  
  const onChange = (e: any) => {
    if(e.target.checked) {
      setChecked([...checked, e.target.value]);
    } else {
      setChecked(checked.filter(c => c !== e.target.value));
    }
  }

  const deleteHandler = async (selectedKeys: any) => {
    await removeMutation({
      variables: {
        keys: selectedKeys
      }
    });
  }

  useEffect(() => {
    if(removeData) {
      setChecked([]);
      refetch();
    }
  }, [removeData]);

  const dataList = data && data?.fileList || [];
  
  return (
    <>
      <LibraryHeader selectedKeys={checked} deleteHandler={deleteHandler} modal={modal} setSearch={setSearch} />
      <Modal 
        bodyStyle={{ padding: 0, lineHeight: 0 }} 
        visible={isModalVisible} 
        footer={null} 
        onCancel={handleCancel}
        zIndex={1020}
      >
        <video ref={video} controls width="100%" src={url}>
          Sorry, your browser doesn't support embedded videos.
        </video>
      </Modal>
      
        <Image.PreviewGroup>
        <Checkbox.Group style={{ width: '100%' }}>
          <List
            pagination={{
              showSizeChanger: true,
              defaultPageSize: 20,
            }}
            grid={{
              gutter: 8,
              xs: 1,
              sm: 2,
              lg: 4,
              xl: 6,
              xxl: 8
            }}
            dataSource={dataList}
            loading={loading}
            renderItem={listing => {
              const file = listing && listing.Key ? `https://instructme.s3.us-east-2.amazonaws.com/${listing.Key}` : '';
              const type = listing && listing.Key?.split('.').slice(-1)[0];
              const isImage = ["png", "jpg", "jpeg", "svg"].some(imageType => imageType === type);
              const isVideo = ["mp4", "mkv", "avi", "mpeg"].some(videoType => videoType === type);
              return (                
                <List.Item>
                  {modal && onFileSelect ? (
                    <Button onClick={() => onFileSelect(file)} className={styles.libraryCheckbox}>Select</Button>
                  ) : (
                    <Checkbox className={styles.libraryCheckbox} value={listing && listing.Key} onChange={onChange} />
                  )}
                  
                  <Card bodyStyle={{ overflow: 'hidden', padding: 0, lineHeight: 0, height: 190, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {isImage ? (
                      <Image
                        src={file}
                      />
                    ) : isVideo ? (
                      <Button type="primary" onClick={() => showModal(file)}>
                        <PlayCircleOutlined />
                      </Button>
                    ) : (
                      <div>Not supported file format</div>
                    )}
                    <Tooltip title={listing && listing.Key}>
                      <div className={styles.fileName}>{listing && listing.Key}</div>
                    </Tooltip>
                    
                  </Card>          
                </List.Item>
              )
            }}
          />
          </Checkbox.Group>
        </Image.PreviewGroup>
      
    </>
  )
  
};

export default LibraryComponent;