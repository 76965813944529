
import { useCookies } from "react-cookie";

export const useAuthToken = (name: string) => {
  
  const [cookies, setCookie, removeCookie] = useCookies([name]);
  
  const setAuthToken = (authToken: string) => setCookie(name, authToken);
  
  const removeAuthToken = () => removeCookie(name);
  
  return [cookies[name], setAuthToken, removeAuthToken];
};