import { useContext } from 'react';
import styles from './tableheader.module.css';
import { Col, Divider, Input, Row, PageHeader } from 'antd';
import { DataTableContext } from '../../context/DataTableContext';

const { Search } = Input;

export interface Props {
  title: string;
  hasSearch?: boolean
}

function Header({ title, hasSearch }: Props) {
  const handleSearch = (value: string) => {
    setSearch(value);
  };
  const { setSearch } = useContext(DataTableContext);
  return (
    <>
      <PageHeader
        title={`${title}`}
        className={styles.title}
      />
      {hasSearch ? <Row>
        <Col>
          <Search
            placeholder="Search"
            onSearch={handleSearch}
            allowClear
            style={{ float: 'left', maxWidth: 350 }}
          />
        </Col>
        <Divider />
      </Row> : null}


    </>
  );
}

export default Header;