import { Alert, Button, Popconfirm, Space, Spin, Upload } from "antd";
import { useEffect, useState } from "react";
import styles from './userAvatar.module.css'

interface Props {
    userID: string;
    useUpdateMutation: any;
    refetch: any
};

const AvatarUploader = (props: Props) => {
    const { userID, useUpdateMutation, refetch } = props
    const [files, setFiles] = useState<any>({});
    const [imageBase64Value, setImageBase64Value] = useState<string>();
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [updateMutation, { loading, data }] = useUpdateMutation();

    const dummyRequest = ({ onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };
    const getBase64Value = (
        img: File | Blob,
        callback: (imageBase64Value: string) => void
    ) => {
        const reader = new FileReader();
        reader.readAsDataURL(img);
        reader.onload = () => {
            callback(reader.result as string);
        };
    };
    useEffect(() => {
        if (data) {
            refetch();
        }
    }, [data]);
    const handleChange = async (info: any, fieldName: string) => {
        setFiles({
            ...files
        });
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === "done" && info.file.originFileObj) {
            getBase64Value(info.file.originFileObj, imageBase64 => {
                setImageBase64Value(
                    imageBase64
                )
                setShowConfirm(true)
            }
            );
        }
    };

    const handleCancelUpdatePhoto = () => {
        setImageBase64Value('')
        setShowConfirm(false)
    };

    const handleUpdatePhoto = async () => {
        await updateMutation({
            variables: {
                id: userID,
                avatar: imageBase64Value
            },
        });
        setShowConfirm(false)
    };


    return (
        <>
            <div className={styles.hover}>
                {showConfirm && <Alert
                    style={{ position: 'absolute', width: 300 }}
                    message="Do you want to change the avatar?"
                    showIcon
                    type="info"
                    action={
                        <Space direction="vertical">
                            {loading ? <Spin size="small" /> : <Button onClick={() => handleUpdatePhoto()} size="small" type="primary">
                                Ok
                            </Button>}
                            <Button onClick={handleCancelUpdatePhoto} size="small" danger type="ghost">
                                Cancel
                            </Button>
                        </Space>
                    }
                />}
                <Upload
                    accept="image/png, image/jpeg"
                    maxCount={1}
                    showUploadList={false}
                    customRequest={dummyRequest}
                    onChange={(e) => handleChange(e, 'avatar')}
                > <img src="/imgUpload.svg" alt="" />
                </Upload>
            </div>
        </>
    )
}
export default AvatarUploader