import DataTable from './DataTable';
import DataTableViewOnly from './DataTableViewOnly';
import { DataTableProvider } from '../../context/DataTableContext';
import { FieldTypes } from '../../components/FormModal';

export interface Props {
  type:string;
  title: string;
  columns: Columns[];
  formFields?: FormFields[];
  useGetQuery: any;
  useCreateMutation?: any;
  useUpdateMutation?: any;
  useRemoveMutation?: any;
  modify: boolean;
  hasDelete: boolean;
  hasCreate: boolean;
  responseObj?: string;
  customRefetch?: any;
  hasSearch?:boolean;
  userObjByType?:{ userId?: string, instructorId?: string };
}
export interface NestedFields {
  name: string;
  type?: FieldTypes;
}

export interface FormFields {
  label: string;
  name: string;
  type: FieldTypes;
  bigImage?: boolean;
  nestedFields?: NestedFields[];
  rules?: {
      required: boolean;
      message?: string;
  }[];
}

export interface Columns {
  title: string;
  dataIndex?: string;
  key?: string;
  render?: (text: any, record: any, index: any) => JSX.Element;
  sorter?: boolean;
  width?: number;
  filters?: any;
  filteredValue?: any;
  sortOrder?: any;
}

const PageData = (props: Props) => {
 
  
  return (
    <DataTableProvider value={props.responseObj}>
      {props.modify ? (
        <DataTable
          {...props}
        />
      ) : (
        <DataTableViewOnly
          {...props}
        />
      )}
      
    </DataTableProvider>
  )
};

export default PageData;
