import { Row, Col, Divider } from 'antd';
import 'antd/dist/antd.css';
import styles from './instructorsProfile.module.css'
import InstructorPersonalInfo from '../../components/Instructor/InstructorPersonalInfo'
import InstructorWorkoutTable from '../../components/Instructor/InstructorWorkout'
import { useInstructor } from "../../graphql.querys/instructors/__generated__/Instructor.query"
import { Spin, Space, Empty } from 'antd';
import { useParams } from "react-router-dom";
import { FieldTypes } from '../../components/FormModal';
import { DataTableProvider } from '../../context/DataTableContext';
import UserPayments from '../../components/UserProfile/UserPayments';
import { UserTypes } from '../../interfaces/User';
import moment from 'moment';
import { stripePaymentsDashboardUrl } from '../Payments/Payments';
import { TablePaymentStatus } from '../../components/Payment/TablePaymentStatus';

const priceDivision = 100;
const columns = [

  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    sorter: true,
    render: (text: any, record: any, index: any) => {
      return (<div >{moment(Number(record.createdAt)).format("L h:mm A")}</div>)
    }
  },
  {
    title: 'User Name',
    dataIndex: 'user',
    key: 'user',
    sorter: true,
    render: (text: any, record: any, index: any) => {
      return (<div >{record && record.user && record.user.name}</div>)
    }
  },
  {
    title: 'Amount ',
    dataIndex: 'price',
    key: 'price',
    sorter: true,
    render: (text: any, record: any, index: any) => {
      return (<div>${record.price / priceDivision}</div>)
    }
  },
  {
    title: 'Course Style',
    dataIndex: 'class',
    key: 'class',
    width: 200,
    render: (text: any, record: any, index: any) => {
      return (<div>{record && record.class && record.class.style}</div>)
    }
  },
  {
    title: 'Course Start Date',
    dataIndex: 'class',
    key: 'class',
    render: (text: any, record: any, index: any) => {
      return (<div>{record && record.class && record.class.startDate && moment(Number(record.class.startDate)).format("L h:mm A")}</div>)
    }
  },
  {
    title: 'Stripe ID',
    dataIndex: 'paymentId',
    key: 'paymentId',
    render: (text: any, record: any, index: any) => {
      return (<a href={`${stripePaymentsDashboardUrl}/${text}`} target={'_blank'} rel={'noreferrer'}>{text}</a>)
    }
  },
  {
    title: 'Payed with credits',
    dataIndex: 'credits',
    key: 'credits',
    render: ((credit: any, record: any, index: any) => {
      return (<div>${credit / priceDivision}</div>)
    })
  },
  {
    title: 'Payment Status',
    dataIndex: 'status',
    key: 'status',
    sorter: true,
    render: (text: any, record: any, index: any) => {
      return (
        <TablePaymentStatus record={record} />
      )
    }
  },
];

export const formFields = [
  {
    label: "Photo",
    name: "promoPhoto",
    type: FieldTypes.IMAGE,
    bigImage: false,
    image: true,
    rules: [
      {
        required: false,
        message: '',
      },
    ]
  },
  {
    label: "Video",
    name: "promoVideo",
    type: FieldTypes.VIDEO,
    bigImage: false,
    image: true,
    rules: [
      {
        required: false,
        message: '',
      },
    ]
  },
  {
    label: "Description",
    name: "description",
    type: FieldTypes.TEXTAREA,
    rules: [
      {
        required: false,
        message: '',
      },
    ]
  },
  {
    label: "Notes",
    name: "notes",
    type: FieldTypes.INPUT,
    rules: [
      {
        required: false,
        message: '',
      },
    ]
  },
  {
    label: "URL",
    name: "url",
    type: FieldTypes.INPUT,
    rules: [
      {
        required: false,
        message: '',
      },
    ]
  },
];

const InstructorsProfile = () => {
  const params: any = useParams();


  const { data, loading, refetch } = useInstructor({
    variables: { id: params.id },
    fetchPolicy: "cache-and-network"
  })

  return (
    loading ?
      <Space style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }} size="middle">
        <Spin size="large" />
      </Space> :
      data && data.instructor ?
        <Row gutter={16} style={{ display: 'flex', flexDirection: 'column' }}>
          <Col className={styles.leftColumn} >
            <DataTableProvider value={'Instructor profile'}>
              <InstructorPersonalInfo
                instructor={data.instructor}
                refetch={refetch}
              />
            </DataTableProvider>
          </Col>
          <Col className={styles.rightColumn} >
            <span className={styles.tableTitle}>Classes</span>
            <InstructorWorkoutTable
              instructor={data.instructor}
              formFields={formFields}
              refetch={refetch}
            />
            <Divider />
            <span className={styles.tableTitle}>Payments</span>
            <UserPayments userType={UserTypes.instructor} columns={columns} />
          </Col>
        </Row> : <Empty />

  )
}
export default InstructorsProfile