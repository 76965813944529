import styles from './library.module.css';
import { Button, Col, Divider, Popconfirm, Row, PageHeader, Input } from 'antd';
import {
  DeleteOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
const { Search } = Input;

export interface Props {
  selectedKeys: Array<any>;
  deleteHandler: any;
  modal?: boolean;
  setSearch: any;
}

function LibraryHeader({ selectedKeys, deleteHandler, modal, setSearch }: Props) {
  
  const handleSearch = (value: string) => {
    setSearch(value);
  };
  return (
    <>
      <PageHeader
        title="Media library"
        className={styles.title}
      />
      <Row className={styles.row}>
        <Col>
          <Search
            placeholder="Search"
            onSearch={handleSearch}
            allowClear
            className={styles.search}
          />
        </Col>
        {!modal && 
          <Col>
            <Popconfirm
              title="Sure to delete?"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              disabled={selectedKeys.length === 0}
              onConfirm={() => {
                deleteHandler(selectedKeys);
              }}
            >
              <Button
                icon={<DeleteOutlined />}
                disabled={selectedKeys.length === 0}
                className={styles.modifyBtn}
              >
                <span className={styles.modifyBtnText}>Delete</span>
              </Button>
            </Popconfirm>
          </Col>
        }
      </Row>
      <Divider />
    </>
  );
}

export default LibraryHeader;