import { Menu, Popconfirm } from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import { useContext, useEffect } from 'react';
import { DataTableContext } from '../../../context/DataTableContext';


interface Props {
    record:any
    useRemoveMutation: any
    refetch:any
}

const WorkoutActions = (props: Props) => {
    const {record:Record, useRemoveMutation, refetch} = props
    const { setVisible, setUpdate, setDefaultFields, setId } = useContext(DataTableContext);
    const [removeMutation, { data }] = useRemoveMutation();

    
    useEffect(() => {
        if (data) {
          refetch();
        }
      }, [data]);
  
    const handleEdit = (value:any)=>{
        setUpdate(true);
        setId(value.id);
        setDefaultFields(value);
        setVisible(true);
    }
    const handleSingleDelete = async (record: any) => {
        await removeMutation({
          variables: {
            id:record.id
          }
        })
      };


    return (
        <Menu mode="horizontal" style={{ borderBottom: 0, background: "transparent" }}>
            <Menu.Item key="edit" onClick={() => handleEdit(Record)} style={{ borderBottom: 0, marginLeft: 0, color: '#57AEA1' }}>
                <EditOutlined />
            </Menu.Item>

            <Menu.Item onClick={(e) => e.domEvent.stopPropagation()} key="delete" style={{ borderBottom: 0, marginLeft: 0, color: '#DF3636' }}>
                <Popconfirm
                    title="Sure to delete?"
                    placement="left"
                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />} 
                    onConfirm={() => handleSingleDelete(Record)}
                >
                    <DeleteOutlined type="delete" />
                </Popconfirm>
            </Menu.Item>
        </Menu>
    )
}

export default WorkoutActions