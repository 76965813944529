import { FC } from 'react';
import './App.css';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import Users from './pages/Users';
import Login from './pages/Login';
import Instructors from './pages/Instructors';
import InstructorsProfile from './pages/Instructors/InstructorsProfile';
import { useAuthToken } from './hooks/useAuthToken';
import { useUser } from './__generated__/User.query';
import LayoutHoc from './hoc/Layout';
import Payments from './pages/Payments';
import UserPayments from './pages/Payments/UserPayments';
import Classes from './pages/Classes';
import UserProfile from './pages/Users/UserProfile';


const App: FC = () => {
  const [authToken, setAuthToken,] = useAuthToken("signedin");
  const { data, loading } = useUser();
  if (authToken === 'false' || (!loading && !data?.user)) {
    return <Login setAuthToken={setAuthToken} />
  }
  return (
    <Router>
      <Switch>
        <LayoutHoc>
          <Route path="/" exact component={Users} />
          <Route path="/instructors" exact component={Instructors} />
          <Route path="/instructors/:id" exact component={InstructorsProfile} />
          <Route path="/user/:id" exact component={UserProfile} />
          <Route path="/payments" exact component={Payments} />
          <Route path="/payments/user/:id" exact component={UserPayments} />
          <Route path="/classes" exact component={Classes} />
        </LayoutHoc>
      </Switch>
    </Router>
  );
};

export default App;