import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../components/Header';
import { Layout, Menu } from 'antd';
import { TeamOutlined, PoundOutlined, UnorderedListOutlined } from '@ant-design/icons';
const { Sider, Content } = Layout;
const { SubMenu } = Menu;

const LayoutHoc = (props: { children: React.ReactNode }) => {
  const history = useHistory();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [collapsed, setCollapsed] = useState(false);
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  const setCollapsedDependsWindow = () => {
    setCollapsed(window.innerWidth < 650);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    setCollapsedDependsWindow();
    return () => {
      window.addEventListener("resize", handleResize);
    }
  }, [window.innerWidth]);

  const handleSiderMenuClick = (action: any) => {
    if (window.innerWidth < 650) setCollapsed(true);
    switch (action.key) {
      case 'users':
        history.push('/');
        break;
      case 'instructors':
        history.push('/instructors');
        break;
      case 'payments':
        history.push('/payments');
        break;
      case 'classes':
        history.push('/classes')
        break;
      default:
        history.push('/');
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header />
      <Layout>
        <Sider
          collapsible
          collapsed={collapsed}
          width={200}
          collapsedWidth={windowWidth < 650 ? 0 : 100}
          onCollapse={(collapsed) => { setCollapsed(collapsed); }}
          className="site-layout-background"
        >
          <Menu
            theme="dark"
            mode="inline"
            style={{ height: '100%', borderRight: 0 }}
            onClick={handleSiderMenuClick}
            selectedKeys={[history.location.pathname.split('/')[1] || 'users']}
          >
            <Menu.Item key="users" icon={<TeamOutlined />}>User list</Menu.Item>

            <Menu.Item key="instructors" icon={<TeamOutlined />}>Instructors</Menu.Item>
            <Menu.Item key="payments" icon={<PoundOutlined />}>Payment list</Menu.Item>
            <Menu.Item key="classes" icon={<UnorderedListOutlined />}>Classes</Menu.Item>

          </Menu>
        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default LayoutHoc;