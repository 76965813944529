import { useParams } from "react-router-dom";
import { useRequests } from "../../graphql.querys/requests/__generated__/Request.query";
import { UserTypes } from "../../interfaces/User";
import PageData from "../PageData";

interface Props {
    userType:string
    columns:any
};

const UserPayments = (props:Props) => {
    const {userType, columns} = props

    const params: { id: string } = useParams();
    const userObjByType: { userId?: string, instructorId?: string } = {};
    if (userType === UserTypes.instructor) {
        userObjByType['instructorId'] = params.id
    } else if (userType === UserTypes.student) {
        userObjByType['userId'] = params.id
    };
    return (
        <div style={{ width: '100%' }}>
            <PageData
                title={''}
                type={'payments'}
                columns={columns}
                formFields={undefined}
                useGetQuery={useRequests}
                useCreateMutation={undefined}
                useUpdateMutation={undefined}
                useRemoveMutation={undefined}
                modify={false}
                hasDelete={false}
                hasCreate={false}
                hasSearch={false}
                responseObj="requests"
                userObjByType={userObjByType}
            />
        </div>
    )
};

export default UserPayments;