import { useEffect, useContext } from 'react';
import styles from './tableheader.module.css';
import { Button, Col, Divider, Input, Popconfirm, Row, PageHeader } from 'antd';
import {
  DeleteOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { DataTableContext } from '../../context/DataTableContext';
import { FormFields } from '../PageData';

const { Search } = Input;

export interface Props { 
  formFields?: FormFields[];
  selectedRowKeys: string[];
  refetch: any;
  useAdd: any;
  hasSelected: boolean;
  title: string;
  hasDelete?: boolean;
  hasCreate?: boolean;
  useRemoveMutation: any;
  modify: boolean;
}

function Header({ selectedRowKeys, refetch, hasSelected, title, hasDelete, hasCreate, useRemoveMutation, modify }: Props) {
  const handleAddNew = () => {
    setVisible(true);
  };
  const handleSearch = (value: string) => {
    setSearch(value);
  };
  const { setVisible, setSearch } = useContext(DataTableContext);
  const [removeMutation, { data }] = useRemoveMutation();
  useEffect(() => {
    if(data) {
      refetch();
    }
  }, [data]);
  return (
    <>
      <PageHeader
        title={`${title}`}
        className={styles.title}
      />
      <Row className={styles.row}>
        <Col>
          <Search
            placeholder="Search"
            onSearch={handleSearch}
            allowClear
            className={styles.search}
          />
        </Col>
        {modify && (
          <Col className={styles.modifyBtns}>
            {hasCreate && (
              <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={handleAddNew}
                className={styles.modifyBtn}
              >
                <span className={styles.modifyBtnText}>Add New</span>
              </Button>
            )}
            
            {hasDelete && (
              <Popconfirm
                title="Sure to delete?"
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                disabled={!hasSelected}
                onConfirm={async () => {
                  await removeMutation({
                    variables: {
                      ids: selectedRowKeys
                    }
                  })
                }}
              >
                <Button
                  icon={<DeleteOutlined />}
                  disabled={!hasSelected}
                  className={styles.modifyBtn}
                >
                  <span className={styles.modifyBtnText}>Delete</span>
                </Button>
              </Popconfirm>
            )}
            
          </Col>
        )}
      </Row>
      <Divider />
    </>
  );
}

export default Header;