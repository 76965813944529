import { Empty, Row, Space, Spin, Divider } from "antd";
import moment from "moment";
import { useParams } from "react-router-dom";
import PageData from "../../components/PageData";
import { TablePaymentStatus } from "../../components/Payment/TablePaymentStatus";
import { useRequests } from "../../graphql.querys/requests/__generated__/Request.query";
import { UserTypes } from "../../interfaces/User";
import { useUserById } from '../../__generated__/User.query'
import { stripePaymentsDashboardUrl } from "./Payments";
import styles from './Payments.module.css'

const priceDivision = 100

const columns = [

    {
        title: 'Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        render: (text: any, record: any, index: any) => {
            return (<div >{moment(Number(record.createdAt)).format("L h:mm A")}</div>)
        }
    },
    {
        title: 'User Name',
        dataIndex: 'user',
        key: 'user',
        render: (text: any, record: any, index: any) => {
            return (<div >{record && record.user && record.user.name}</div>)
        }
    },

    {
        title: 'Amount ',
        dataIndex: 'price',
        key: 'price',
        sorter: true,
        render: (text: any, record: any, index: any) => {
            return (<div>${record.price / priceDivision}</div>)
        }
    },
    {
        title: 'Course Style',
        dataIndex: 'class',
        key: 'class',
        width: 200,
        render: (text: any, record: any, index: any) => {
            return (<div>{record && record.class && record.class.style}</div>)
        }
    },
    {
        title: 'Course Instructor',
        dataIndex: 'instructor',
        key: 'instructor',

        render: (text: any, record: any, index: any) => {
            return (<div>{record && record.instructor && record.instructor.name}</div>)
        }
    },
    {
        title: 'Course Start Date',
        dataIndex: 'class',
        key: 'class',
        render: (text: any, record: any, index: any) => {
            return (<div>{record && record.class && record.class.startDate && moment(Number(record.class.startDate)).format("L h:MM A")}</div>)
        }
    },
    {
        title: 'Stripe ID',
        dataIndex: 'paymentId',
        key: 'paymentId',
        render: (text: any, record: any, index: any) => {
            return(<a href={`${stripePaymentsDashboardUrl}/${text}`} target={'_blank'} rel={'noreferrer'}>{text}</a>)
        }
    },
    {
        title: 'Payed with credits',
        dataIndex: 'credits',
        key: 'credits',
        render: ((credit: any, record: any, index: any) => {
            return (<div>${credit / priceDivision}</div>)
        })
    },
    {
        title: 'Payment Status',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        render: (text: any, record: any, index: any) => {
            return (
                <TablePaymentStatus  record={record} />
            )
        }
    },
];

const UserPayments = (props: any) => {

    const params: { id: string } = useParams();

    const { data, loading } = useUserById({
        variables: { id: params.id },
        fetchPolicy: "cache-and-network"
    });
    const userObjByType: { userId?: string, instructorId?: string } = {};
    if (data && data.userById.type === UserTypes.instructor) {
        userObjByType['instructorId'] = data.userById.id
    } else if (data && data.userById.type === UserTypes.student) {
        userObjByType['userId'] = data.userById.id
    };

    return (
        loading ?
            <Space style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }} size="middle">
                <Spin size="large" />
            </Space> :
            data && data.userById ?
                <Row className={styles.userPaymentsContainer}>
                    <div style={{
                        fontSize: 24,
                        fontWeight: 600
                    }}>{data.userById.name}</div>
                    <div style={{
                        fontSize: 16,
                        fontWeight: 600
                    }}>Email: {data.userById.email}</div>
                    <div style={{
                        fontSize: 16,
                        fontWeight: 600
                    }}>User type: {data.userById.type}</div>
                    {data.userById.blocked ? <div style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: 'red'
                    }}>User is Blocked!</div> : null}
                    {data.userById.deleted ? <div style={{
                        fontSize: 20,
                        fontWeight: 600,
                        color: 'red'
                    }}>User is Deleted!</div> : null}

                    <Divider />
                    <div style={{ width: '100%' }}>
                        <PageData
                            title={`${data.userById.name}'s payment list`}
                            type={'payments'}
                            columns={columns}
                            formFields={undefined}
                            useGetQuery={useRequests}
                            useCreateMutation={undefined}
                            useUpdateMutation={undefined}
                            useRemoveMutation={undefined}
                            modify={false}
                            hasDelete={false}
                            hasCreate={false}
                            hasSearch={false}
                            responseObj="requests"
                            userObjByType={userObjByType}
                        />
                    </div>
                </Row>
                : <Empty />
    )
};

export default UserPayments