import { useEffect, useState } from "react";
import UserListModal from "../../UserListModal";
import styles from './userPurchasedCount.module.css'

export const UserPurchasedCounter = (classID: string, useGetQuery: any, requestName: string, columns: any, tableTitle:string) => {

    const [studentsCount, setStudentsCount] = useState<number>(0)
    const [modalVisibility, setModalVisibility] = useState<boolean>(false)

    const { data } = useGetQuery({
        variables: {
            id: classID,
        }
    });
    useEffect(() => {
        if (data) {
            setStudentsCount(data[requestName].total)
        }
    }, [data]);

    const handleClick = () => {
        setModalVisibility(true)
    };
    const handleCloseModal = () => {
        setModalVisibility(false)
    };

    return (
        <div>
            <div className={styles.countBox} onClick={handleClick}>{studentsCount}</div>
            {modalVisibility && <UserListModal
                tableTitle={tableTitle}
                classID={classID}
                modalVisibility={modalVisibility}
                columns={columns}
                responseObjName={requestName}
                useGetQuery={useGetQuery}
                handleCloseModal={handleCloseModal}
            />}
        </div>
    )
}