import { Badge, Table } from 'antd';
import { IUser } from '../../interfaces/User'
import WorkoutActions from './workoutActions/WorkoutActions';
import { DataTableProvider } from '../../context/DataTableContext';
import { FormFields } from '../PageData';
import FormModal from '../FormModal';
import { useUpdateClass, useRemoveClass } from '../../graphql.querys/classes/__generated__/classes.mutation'
import { useDeletedRequestsByClassId, useRequestsByClassId } from '../../graphql.querys/requests/__generated__/Request.query'

import { getUsernameAvatar } from '../UserAvatar';
import moment from 'moment';
import { UserPurchasedCounter } from './UserPurchasedCount';
import { priceDivision, stripePaymentsDashboardUrl } from '../../pages/Payments/Payments';


interface Props {
    instructor: IUser,
    formFields?: FormFields[]
    refetch: any
}
const UserPurchasedcolumns = [
    {
        title: 'Image',
        dataIndex: 'avatar',
        key: 'avatar',
        width: 100,
        render: (src: any, record: any, index: any) => getUsernameAvatar(record.user.name, record.user.avatar),
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text: any, record: any, index: any) => <div>{record.user.name}</div>
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: (text: any, record: any, index: any) => <div>{record.user.email}</div>
    },
    {
        title: 'Stripe ID',
        dataIndex: 'paymentId',
        key: 'paymentId',
        render: (text: any, record: any, index: any) => {
            return (<a href={`${stripePaymentsDashboardUrl}/${text}`} target={'_blank'} rel={'noreferrer'}>{text}</a>)
        }
    },
    {
        title: 'Payed with credits',
        dataIndex: 'credits',
        key: 'credits',
        render: ((credit: any, record: any, index: any) => {
            return (<div>${credit / priceDivision}</div>)
        })
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        render: ((price: any, record: any, index: any) => {
            return (<div>${price / priceDivision}</div>)
        })
    },
    {
        title: 'Payment Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: any, record: any, index: any) => {
            return (<div >{moment(Number(text)).format("L h:mm A")}</div>)
        }
    },
    {
        title: 'User Status',
        dataIndex: 'blocked',
        key: 'blocked',
        width: 100,
        render: (value: any, record: any, index: any) => {
            return (
                record.user.deleted ?
                    <Badge color={'red'} text={'Deleted'} /> :
                    <Badge color={record.user.blocked ? 'red' : 'green'} text={value ? 'Blocked' : 'Active'} />
            )
        },
    },
];
const UsersCanseledClasscolumns = [
    {
        title: 'Image',
        dataIndex: 'avatar',
        key: 'avatar',
        width: 100,
        render: (src: any, record: any, index: any) => getUsernameAvatar(record.user.name, record.user.avatar),
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text: any, record: any, index: any) => <div>{record.user.name}</div>
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: (text: any, record: any, index: any) => <div>{record.user.email}</div>
    },
    {
        title: 'Stripe ID',
        dataIndex: 'paymentId',
        key: 'paymentId',
        render: (text: any, record: any, index: any) => {
            return (<a href={`${stripePaymentsDashboardUrl}/${text}`} target={'_blank'} rel={'noreferrer'}>{text}</a>)
        }
    },
    {
        title: 'Payed with credits',
        dataIndex: 'credits',
        key: 'credits',
        render: ((credit: any, record: any, index: any) => {
            return (<div>${credit / priceDivision}</div>)
        })
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        render: ((price: any, record: any, index: any) => {
            return (<div>${price / priceDivision}</div>)
        })
    },
    {
        title: 'Canceled Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: any, record: any, index: any) => {
            return (<div >{moment(Number(text)).format("L h:mm A")}</div>)
        }
    },
    {
        title: 'User Status',
        dataIndex: 'blocked',
        key: 'blocked',
        width: 100,
        render: (value: any, record: any, index: any) => {
            return (
                record.user.deleted ?
                    <Badge color={'red'} text={'Deleted'} /> :
                    <Badge color={record.user.blocked ? 'red' : 'green'} text={value ? 'Blocked' : 'Active'} />
            )
        },
    },
];

const InstructorWorkout = (props: Props) => {
    const { instructor, formFields, refetch } = props;
    const workoutColums = [
        {
            title: 'No',
            dataIndex: 'key',
            key: 'key',
            width: 100,
            render: (text: any, record: any, index: any) => {
                return index + 1
            },
        },
        {
            title: 'Image',
            dataIndex: 'promoPhoto',
            key: 'promoPhoto',
            width: 100,
            render: (src: any, record: any, index: any) => getUsernameAvatar(record.style ? record.style : 'Image', src),
        },
        {
            title: 'Style',
            dataIndex: 'style',
            key: 'style',
            width: 50

        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width: 250
        },
        {
            title: 'Client Room Url',
            dataIndex: 'roomUrl',
            key: 'roomUrl',
            width: 100,
            render: (src: any, record: any, index: any) => {
                return (<a href={src} target={'_blank'} rel="noreferrer">{src ? 'Client Url' : ''}</a>)
            }
        },
        {
            title: 'Host Room Url',
            dataIndex: 'hostRoomUrl',
            key: 'hostRoomUrl',
            width: 100,
            render: (src: any, record: any, index: any) => {
                return (<a href={src} target={'_blank'} rel="noreferrer">{src ? 'Host Url' : ''}</a>)
            }
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: 250
        },
        {
            title: 'Recurring type',
            dataIndex: 'recurringType',
            key: 'recurringType',
            width: 50

        },
        {
            title: 'Users Purchased ',
            dataIndex: 'timeSlots',
            key: 'timeSlots',
            width: 50,
            render: (text: any, record: any, index: any) => UserPurchasedCounter(record.id, useRequestsByClassId, "requestsByClassId", UserPurchasedcolumns, "Users Purchased")
        },
        {
            title: 'Refund to users',
            dataIndex: 'timeSlots',
            key: 'timeSlots',
            width: 50,
            render: (text: any, record: any, index: any) => UserPurchasedCounter(record.id, useDeletedRequestsByClassId, "deletedRequestsByClassId", UsersCanseledClasscolumns, 'List of users who made a purchase before canceling a class or time slot')
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            width: 100,
            render: (text: any, record: any, index: any) => {
                return (<div >{moment(Number(record.startDate)).format("L h:MM A")}</div>)
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text: any, record: any, index: any) => (<WorkoutActions refetch={refetch} useRemoveMutation={useRemoveClass} record={record} />),

        }

    ]

    return (
        <DataTableProvider value={'classes'}>
            <FormModal
                formFields={formFields}
                refetch={refetch}
                customRefetch={undefined}
                useCreateMutation={undefined}
                useUpdateMutation={useUpdateClass}
                title={'workout'}
            />
            <Table
                columns={workoutColums}
                rowKey={record => record.id}
                dataSource={instructor && instructor.classes}
            />
        </DataTableProvider>

    )
}

export default InstructorWorkout