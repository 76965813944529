import styles from './header.module.css';
import { useAuthToken } from '../../hooks/useAuthToken';
import { getUsernameAvatar } from '../UserAvatar';
import {
  LogoutOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import Logo from '../../logo.svg';
const { Header } = Layout;
const { SubMenu } = Menu;


const HeaderComponent = () => {
  const [, setToken] = useAuthToken("signedin");
  const handleSettingMenuClick = (action: any) => {
    switch (action.key) {
      case 'logout':
        setToken(false);
        break;
      default:
        break;
    }
  };
  const handleLogout = () => {};
  return (
    <Header className={styles.header}>
      <div className="logo"><img style={{ height: '50%' }} src={Logo} alt="TipTopTone logo" /></div>
      <Menu onClick={handleSettingMenuClick} mode="horizontal" className="menu">
        <SubMenu title={getUsernameAvatar('Admin')}>
          {/* <Menu.Item key="settings">
            <span>
              <SettingOutlined />
              Settings
            </span>
          </Menu.Item> */}
          <Menu.Item key="logout">
            <span>
              <LogoutOutlined onClick={handleLogout} />
              Logout
            </span>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Header>
  );
}

export default HeaderComponent;