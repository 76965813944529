import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type LogInVariables = Types.Exact<{
  name?: Types.Maybe<Types.Scalars['String']>;
  password?: Types.Maybe<Types.Scalars['String']>;
}>;


export type LogIn = { __typename: 'Mutation', logIn: { __typename: 'Viewer', id?: string | null | undefined } };


export const LogInDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"LogIn"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"name"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"password"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"logIn"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"name"},"value":{"kind":"Variable","name":{"kind":"Name","value":"name"}}},{"kind":"Argument","name":{"kind":"Name","value":"password"},"value":{"kind":"Variable","name":{"kind":"Name","value":"password"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode;
export type LogInMutationFn = Apollo.MutationFunction<LogIn, LogInVariables>;

/**
 * __useLogIn__
 *
 * To run a mutation, you first call `useLogIn` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogIn` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logIn, { data, loading, error }] = useLogIn({
 *   variables: {
 *      name: // value for 'name'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLogIn(baseOptions?: Apollo.MutationHookOptions<LogIn, LogInVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogIn, LogInVariables>(LogInDocument, options);
      }
export type LogInHookResult = ReturnType<typeof useLogIn>;
export type LogInMutationResult = Apollo.MutationResult<LogIn>;
export type LogInMutationOptions = Apollo.BaseMutationOptions<LogIn, LogInVariables>;