import { Form, Spin, Input, Button, Checkbox, Row, Col, Card, Typography, Layout } from 'antd';
import { useLogIn } from './__generated__/Login.mutation';
import { ErrorBanner } from '../../components/ErrorBanner';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export interface Props {
  setAuthToken: (authToken: boolean) => void;
}

const Login = (props: Props) => {
  const [logIn, { data, loading, error }] = useLogIn();
  const onFinish = async (values: any) => {
    try {
      await logIn({
        variables: {
           name: values.username,
           password: values.password
        },
      });
    } catch (error) {
      props.setAuthToken(false);
    }
  };
  if(!loading && data?.logIn.id) {
    props.setAuthToken(true);
    window.location.reload();
  }
  

  const logInErrorBannerElement = error ? (
    <ErrorBanner description={error.message} />
  ) : null;

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Layout>
      {logInErrorBannerElement}
      <Row justify="center" align="middle"  style={{minHeight: '100vh'}}>
        <Col>
          <Spin spinning={loading}>
            <Card>
              <Typography.Title level={2}>Signin</Typography.Title>
              <Form
                {...layout}
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your username!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Spin>
        </Col>
      </Row>
    </Layout>
  );
};

export default Login;