import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type RemoveFilesVariables = Types.Exact<{
  keys?: Types.Maybe<Array<Types.Maybe<Types.Scalars['String']>> | Types.Maybe<Types.Scalars['String']>>;
}>;


export type RemoveFiles = { __typename: 'Mutation', removeFiles?: { __typename: 'RemovedFiles', Deleted?: Array<{ __typename: 'S3Key', Key?: string | null | undefined } | null | undefined> | null | undefined } | null | undefined };


export const RemoveFilesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveFiles"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"keys"}},"type":{"kind":"ListType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeFiles"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"keys"},"value":{"kind":"Variable","name":{"kind":"Name","value":"keys"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"Deleted"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"Key"}}]}}]}}]}}]} as unknown as DocumentNode;
export type RemoveFilesMutationFn = Apollo.MutationFunction<RemoveFiles, RemoveFilesVariables>;

/**
 * __useRemoveFiles__
 *
 * To run a mutation, you first call `useRemoveFiles` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFiles` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFiles, { data, loading, error }] = useRemoveFiles({
 *   variables: {
 *      keys: // value for 'keys'
 *   },
 * });
 */
export function useRemoveFiles(baseOptions?: Apollo.MutationHookOptions<RemoveFiles, RemoveFilesVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFiles, RemoveFilesVariables>(RemoveFilesDocument, options);
      }
export type RemoveFilesHookResult = ReturnType<typeof useRemoveFiles>;
export type RemoveFilesMutationResult = Apollo.MutationResult<RemoveFiles>;
export type RemoveFilesMutationOptions = Apollo.BaseMutationOptions<RemoveFiles, RemoveFilesVariables>;