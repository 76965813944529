import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UpdateClassVariables = Types.Exact<{
  data?: Types.Maybe<Types.Scalars['String']>;
}>;


export type UpdateClass = { __typename: 'Mutation', class: { __typename: 'Classes', id?: string | null | undefined, style?: string | null | undefined, roomUrl?: string | null | undefined, hostRoomUrl?: string | null | undefined, address?: string | null | undefined, recurringType?: string | null | undefined, description?: string | null | undefined, online?: boolean | null | undefined, price?: string | null | undefined, endDate?: string | null | undefined, notes?: string | null | undefined, promoPhoto?: string | null | undefined, promoVideo?: string | null | undefined, recommendedAddress?: string | null | undefined } };

export type RemoveClassVariables = Types.Exact<{
  id?: Types.Maybe<Types.Scalars['String']>;
}>;


export type RemoveClass = { __typename: 'Mutation', removeClass: { __typename: 'Removed', deletedCount?: number | null | undefined } };


export const UpdateClassDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateClass"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"data"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"class"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"data"},"value":{"kind":"Variable","name":{"kind":"Name","value":"data"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"style"}},{"kind":"Field","name":{"kind":"Name","value":"roomUrl"}},{"kind":"Field","name":{"kind":"Name","value":"hostRoomUrl"}},{"kind":"Field","name":{"kind":"Name","value":"address"}},{"kind":"Field","name":{"kind":"Name","value":"recurringType"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"online"}},{"kind":"Field","name":{"kind":"Name","value":"price"}},{"kind":"Field","name":{"kind":"Name","value":"endDate"}},{"kind":"Field","name":{"kind":"Name","value":"notes"}},{"kind":"Field","name":{"kind":"Name","value":"promoPhoto"}},{"kind":"Field","name":{"kind":"Name","value":"promoVideo"}},{"kind":"Field","name":{"kind":"Name","value":"recommendedAddress"}}]}}]}}]} as unknown as DocumentNode;
export type UpdateClassMutationFn = Apollo.MutationFunction<UpdateClass, UpdateClassVariables>;

/**
 * __useUpdateClass__
 *
 * To run a mutation, you first call `useUpdateClass` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClass` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClass, { data, loading, error }] = useUpdateClass({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateClass(baseOptions?: Apollo.MutationHookOptions<UpdateClass, UpdateClassVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClass, UpdateClassVariables>(UpdateClassDocument, options);
      }
export type UpdateClassHookResult = ReturnType<typeof useUpdateClass>;
export type UpdateClassMutationResult = Apollo.MutationResult<UpdateClass>;
export type UpdateClassMutationOptions = Apollo.BaseMutationOptions<UpdateClass, UpdateClassVariables>;
export const RemoveClassDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveClass"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeClass"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deletedCount"}}]}}]}}]} as unknown as DocumentNode;
export type RemoveClassMutationFn = Apollo.MutationFunction<RemoveClass, RemoveClassVariables>;

/**
 * __useRemoveClass__
 *
 * To run a mutation, you first call `useRemoveClass` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClass` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClass, { data, loading, error }] = useRemoveClass({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveClass(baseOptions?: Apollo.MutationHookOptions<RemoveClass, RemoveClassVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveClass, RemoveClassVariables>(RemoveClassDocument, options);
      }
export type RemoveClassHookResult = ReturnType<typeof useRemoveClass>;
export type RemoveClassMutationResult = Apollo.MutationResult<RemoveClass>;
export type RemoveClassMutationOptions = Apollo.BaseMutationOptions<RemoveClass, RemoveClassVariables>;