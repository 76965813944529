import {IClass} from './Class'
export enum UserTypes {
  student = 'student',
  instructor = 'instructor',

}
export enum Role {
  user = 'user',
  admin = 'admin'
}



export enum DayTime {
  morning = 'morning',
  evening = 'evening',
  afternoon = 'afternoon'
}

export enum Rating {
  allRatings = 'allRatings',
  fourAndHigher = 'fourAndHigher'
}

export enum ExperienceType {
  year = 'year',
  month = 'month'
}
export interface ILocation {
  type: string;
  coordinates: number[];
}


export interface IUser {
  id: string
  name?: string|null;
  email?: string|null;
  role?: Role|null;
  type?: UserTypes|null;
  facebookId?: string|null;
  googleId?: string|null;
  appleId?: string|null;
  avatar?: string|null;
  location?: ILocation|null;
  address?: string|null;
  getSignedJwtToken?(): string|null
  matchPassword?(enteredPassword: string): boolean|null;
  profile?: IProfile;
  classes?: IClass[]|any
  favourites?: IUser[]|null
  favourite?: boolean|null;
  stripeId?: string|null
  credits?: number|null;
  pending?: number|null;
  isStripeVerified?: boolean|null;
  isSelectedCurrentLocation?: boolean|null;
  blocked?: boolean|null;
}
export interface IReviewList {
  total: number;
  average: number;
  reviews?: IReview[];
}
export interface IReview {
  user: IUser | string;
  instructor: IUser | string;
  class: [] | string
  rating: number;
  message: string;
}

export interface IProfile {
  _id?: string|null;
  id?: string|null;
  classes?:any;
  experience?: number|null;
  experienceType?: string|null;
  certification?: boolean|null;
  certificates?: any;
  certificateFiles?: any;
  photos?: any;
  instagramLink?: string|null;
  description?: string|null;
  createdAt?:any
  // reviews?: IReviewList|null;
}