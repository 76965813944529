import { Button, Modal, Table } from "antd";
import { useState } from "react";

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 1;
interface Props {
    classID?: string
    modalVisibility: boolean
    columns: any
    useGetQuery: any
    responseObjName: string
    tableTitle:string
    handleCloseModal: () => void
}

const UserListModal = (props: Props) => {
    const { modalVisibility, classID, columns, useGetQuery, handleCloseModal, responseObjName, tableTitle } = props
    const [visible, setVisible] = useState<boolean>(modalVisibility)
    const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

    const handleCancel = () => {
        setVisible(false)
        handleCloseModal()
    };

    const { data, loading } = useGetQuery({
        variables: {
            id: classID,
            limit: pageSize,
            page: currentPage
        }
    });

    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    return (
        <>
            <Modal
                width={1200}
                visible={visible}
                title={tableTitle}
                onCancel={handleCancel}
                footer={[
                    <Button key="Close" onClick={handleCancel}>
                        Close
                    </Button>,
                ]}
            >
                <Table
                    rowKey={record => record.id}
                    loading={loading}
                    dataSource={data && data[responseObjName] && data[responseObjName].result}
                    columns={columns}
                    onChange={handleTableChange}
                    pagination={{
                        showSizeChanger: true,
                        pageSize: pageSize,
                        current: currentPage ,
                        total: data && data[responseObjName].total,
                        showTotal: (total, range) => {
                            return `${range[0]}-${range[1]} of ${total} items`;
                        },
                    }}
                />
            </Modal>
        </>
    );
}

export default UserListModal