import { Badge } from 'antd';
import { useUsers } from '../Users/__generated__/Users.query';
import { useUpdateUser, useRemoveUsers } from '../Users/__generated__/Users.mutation';
import PageData from '../../components/PageData';
import { getUsernameAvatar } from '../../components/UserAvatar';
import { FieldTypes } from '../../components/FormModal'
import { UserTypes } from '../../interfaces/User'
import moment from 'moment';

export const columns = [
  {
    title: 'Image',
    dataIndex: 'avatar',
    key: 'avatar',
    width: 100,
    render: (src: any, record: any, index: any) => getUsernameAvatar(record.name, src),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
  },
  {
    title: 'Status',
    dataIndex: 'blocked',
    key: 'blocked',
    sorter: true,
    width: 100,
    render: (value: any, record: any, index: any) => <Badge color={value ? 'red' : 'green'} text={value ? 'Blocked' : 'Active'} />,
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 50,
    sorter: true,
    render: (text: any, record: any, index: any) => {
      return (<div >{moment(Number(record.createdAt)).format("L h:mm A")}</div>)
    }
  },
  {
    title: 'Last Login',
    dataIndex: 'lastLogin',
    key: 'lastLogin',
    width: 50,
    sorter: true,
    render: (text: any, record: any, index: any) => {
      return (record.lastLogin ? <div >{moment(Number(record.lastLogin)).format("L h:mm A")}</div> : <div></div>)
    }
  },
];

export const formFields = [
  {
    label: "Avatar",
    name: "avatar",
    type: FieldTypes.IMAGE,
    bigImage: false,
    image: true,
    rules: [
      {
        required: true,
        message: 'Image is required!',
      },
    ]
  },
  {
    label: "Name",
    name: "name",
    type: FieldTypes.INPUT,
    rules: [
      {
        required: true,
        message: 'Name is required!',
      },
    ]
  },
  {
    label: "Block user",
    name: "blocked",
    type: FieldTypes.CHECKBOX,
    rules: [
      {
        required: false,
        message: '',
      },
    ]
  },
];

const Instructors = () => {

  return (
    <PageData
      title="Instructor list"
      type={UserTypes.instructor}
      columns={columns}
      formFields={formFields}
      useGetQuery={useUsers}
      useCreateMutation={useUpdateUser}
      useUpdateMutation={useUpdateUser}
      useRemoveMutation={useRemoveUsers}
      modify={true}
      hasDelete={true}
      hasCreate={false}
      hasSearch={true}
      responseObj="users"
    />
  )
};

export default Instructors;
