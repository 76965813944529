import Header from '../TableHeader/TableHeaderViewOnly';
import useDataTableViewOnly from '../../hooks/useDataTableViewOnly';
import { Props } from './index';

const DataTable = ({ title, columns, useGetQuery, hasSearch, userObjByType }: Props) => {
  const {
    DataTable,
  } = useDataTableViewOnly({
    columns,
    useGetQuery,
    userObjByType
  });
  return (
    <>
      <Header
        title={title}
        hasSearch={hasSearch}
      />
      <DataTable />
    </>
  )
};

export default DataTable;