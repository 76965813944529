import { IClass } from "./Class";
import { IUser } from "./User";

export enum PaymentStatus {
    NEW = 'NEW',
    HOLD = 'HOLD',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED'
  }

  export interface IRequest {
    user: IUser;
    class: IClass;
    instructor: IUser;
    timeSlot: any;
    style?: string;
    group?: boolean;
    quantity?: number;
    online?: boolean;
    comment?: string;
    address?: string;
    location?: any;
    status: PaymentStatus;
    paymentId?: string;
    transferId?: string;
    price?: number;
    credits?: number;
    userName?: string,
    instructorName?: string,
  }