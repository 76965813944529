import styles from './UserProfile.module.css';
import { useUserById } from '../../__generated__/User.query'
import { useParams } from 'react-router-dom';
import { Col, Divider, Empty, Row, Space, Spin } from 'antd';
import { DataTableProvider } from '../../context/DataTableContext';
import UserPersonalInfo from '../../components/UserProfile/UserPersonallInfo';
import UserPayments from '../../components/UserProfile/UserPayments';
import { UserTypes } from '../../interfaces/User';
import moment from 'moment';
import { stripePaymentsDashboardUrl } from '../Payments/Payments';
import { TablePaymentStatus } from '../../components/Payment/TablePaymentStatus';

const priceDivision = 100
const columns = [

    {
        title: 'Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        render: (text: any, record: any, index: any) => {
            return (<div >{moment(Number(record.createdAt)).format("L h:mm A")}</div>)
        }
    },
    {
        title: 'Course Instructor',
        dataIndex: 'instructor',
        key: 'instructor',
        sorter: true,
        render: (text: any, record: any, index: any) => {
            return (<div>{record && record.instructor && record.instructor.name}</div>)
        }
    },

    {
        title: 'Amount ',
        dataIndex: 'price',
        key: 'price',
        sorter: true,
        render: (text: any, record: any, index: any) => {
            return (<div>${record.price / priceDivision}</div>)
        }
    },
    {
        title: 'Course Style',
        dataIndex: 'class',
        key: 'class',
        width: 200,
        render: (text: any, record: any, index: any) => {
            return (<div>{record && record.class && record.class.style}</div>)
        }
    },
    {
        title: 'Course Start Date',
        dataIndex: 'class',
        key: 'class',
        render: (text: any, record: any, index: any) => {
            return (<div>{record && record.class && record.class.startDate && moment(Number(record.class.startDate)).format("L h:MM A")}</div>)
        }
    },
    {
        title: 'Stripe ID',
        dataIndex: 'paymentId',
        key: 'paymentId',
        render: (text: any, record: any, index: any) => {
            return (<a href={`${stripePaymentsDashboardUrl}/${text}`} target={'_blank'} rel={'noreferrer'}>{text}</a>)
        }
    },
    {
        title: 'Payed with credits',
        dataIndex: 'credits',
        key: 'credits',
        render: ((credit: any, record: any, index: any) => {
            return (<div>${credit / priceDivision}</div>)
        })
    },
    {
        title: 'Payment Status',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        render: (text: any, record: any, index: any) => {
            return (
                <TablePaymentStatus record={record} />
            )
        }
    },
];

const UserProfile = () => {
    const params: any = useParams();

    const { data, loading, refetch } = useUserById({
        variables: { id: params.id },
        fetchPolicy: "cache-and-network"
    })
    return (
        loading ?
            <Space style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }} size="middle">
                <Spin size="large" />
            </Space> :
            data && data.userById ?
                <Row gutter={16} style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Col className={styles.userPersonalInfo__Container} >
                        <DataTableProvider value={'User profile'}>
                            <UserPersonalInfo user={data.userById} refetch={refetch} />
                        </DataTableProvider>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <Divider />
                            <span className={styles.tableTitle}>Payments</span>
                            <UserPayments
                                userType={UserTypes.student}
                                columns={columns} />
                        </div>
                    </Col>
                </Row> : <Empty />

    )
};
export default UserProfile