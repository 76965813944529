import styles from './instructor.module.css'
import { Avatar, Divider, Tag, Image, Space, Button, Popconfirm, Empty } from 'antd';
import { UserOutlined, MailOutlined, InstagramOutlined } from '@ant-design/icons';
import { DataTableContext } from '../../context/DataTableContext';
import { IUser } from '../../interfaces/User'
import moment from 'moment'
import FormModal, { FieldTypes } from '../FormModal';
import { useUpdateUser, useUpdateProfile } from '../../pages/Users/__generated__/Users.mutation';
import { useContext, useEffect } from 'react';
import Title from 'antd/lib/typography/Title';
import AvatarUploader from '../UserAvatar/AvatarUploader';
import QuestionCircleOutlined from '@ant-design/icons/lib/icons/QuestionCircleOutlined';
interface Props {
  instructor: IUser
  refetch: any
}
export enum ExperienceType {
  year = 'year',
  month = 'month'
}

const formFieldsProfile = [
  {
    label: "Name",
    name: "name",
    type: FieldTypes.INPUT,
    rules: [
      {
        required: true,
        message: 'Name is required',
      },
    ]
  },
  {
    label: "Email",
    name: "email",
    type: FieldTypes.INPUT,
    rules: [
      {
        required: true,
        type: 'email'
      },
    ]
  },
  {
    label: "Instagram link",
    name: "instagramLink",
    type: FieldTypes.INPUT,
    rules: [
      {
        required: false,
        message: '',
      },
    ]
  },
  {
    label: "Experience",
    name: "experience",
    type: FieldTypes.INPUT_NUMBER,
    rules: [
      {
        required: true,
        message: 'Experience is required',
      },
    ]
  },
  {
    label: "Experience type",
    name: "experienceType",
    type: FieldTypes.SELECT,
    options: [
      {
        name: 'Year',
        value: ExperienceType.year
      },
      {
        name: 'Month',
        value: ExperienceType.month
      }
    ],
    rules: [
      {
        required: true,
        message: 'Experience type is required',
      },
    ]
  },
  {
    label: "Description",
    name: "description",
    type: FieldTypes.TEXTAREA,
    rules: [
      {
        required: true,
        message: 'Description is required',
      },
    ]
  },
  {
    label: "Classes",
    name: "classes",
    type: FieldTypes.TAGS,
    rules: [
      {
        required: false,
        message: '',
      },
    ]
  },
  {
    label: "Certificates",
    name: "certificates",
    type: FieldTypes.TAGS,
    rules: [
      {
        required: false,
        message: '',
      },
    ]
  },
  {
    label: "Certificate photos",
    name: "certificateFiles",
    type: FieldTypes.IMAGES,
    rules: [
      {
        required: false,
        message: '',
      },
    ]
  },
  {
    label: "Photos",
    name: "photos",
    type: FieldTypes.IMAGES,
    rules: [
      {
        required: false,
        message: '',
      },
    ]
  },

]
const InstructorPersonalInfo = (props: Props) => {
  const { instructor, refetch } = props
  const { setVisible, setUpdate, setDefaultFields, setId, setResponseObj, setProfileID, setDefaultProfileFields } = useContext(DataTableContext);
  const [updateMutation, { data }] = useUpdateUser();

  useEffect(() => {
    if (data) {
      refetch()
    }
  }, [data])

  const handleEditProfile = (record: any) => {
    const user = { ...record }
    delete user.classes
    setResponseObj('profile');
    setUpdate(true);
    setId(user.id);
    setProfileID(user.profile.id)
    setDefaultProfileFields(user.profile)
    setDefaultFields(user);
    setVisible(true);
  };

  const BlockUserState = async (userID: string, blocked: any) => {
    await updateMutation({
      variables: {
        id: userID,
        blocked: !blocked
      }
    })
  }

  return (
    instructor && instructor.profile ? <div className={styles.personalInfoContainer}>
      <FormModal
        formFields={formFieldsProfile}
        refetch={refetch}
        customRefetch={undefined}
        useCreateMutation={undefined}
        useUpdateMutation={useUpdateProfile}
        title={'Profile'}
      />
      <div className={styles.UserGeneralInfo}>
        <div className={styles.UserGeneralInfo__about}>
          <div className={styles.UserGeneralInfo__about__avatarBox}>
            <Avatar style={{ backgroundColor: '#F4F5F8' }} src={`${instructor.avatar}`}
              size={140} icon={<UserOutlined style={{ color: '#C8CCD9' }} />} />
            <div className={styles.uploadImage}>
              <AvatarUploader
                userID={instructor.id}
                useUpdateMutation={useUpdateUser}
                refetch={refetch} />
            </div>
          </div>
          <div className={styles.UserGeneralInfo__about__infoBox}>
            <Title level={3}>{instructor.name}</Title>
            <div className={styles.infoBox__LinksBox}>
              <div className={styles.infoBox__LinksBox__link}>
                <MailOutlined style={{ fontSize: '20px' }} />
                <a href={`mailto:${instructor.email}`} target={'_blank'} rel="noreferrer" style={{ marginLeft: 15 }}>{instructor.email}</a>
              </div>
              {instructor.profile?.instagramLink && <div style={{ marginLeft: 25 }} className={styles.infoBox__LinksBox__link}>
                <InstagramOutlined style={{ fontSize: '20px', color: '#C8CCD9' }} />
                <a href={instructor.profile?.instagramLink} target={'_blank'} rel="noreferrer" style={{ marginLeft: 15 }}>{instructor.profile?.instagramLink}</a>
              </div>}
            </div>
            <div style={{
              color: '#989CA9',
              fontSize: 16,
              marginTop: 15
            }}>Experience: <span style={{ color: 'black' }}>{`${instructor.profile.experience} ${instructor.profile.experienceType}`}</span>
            </div>
            <div style={{
              color: '#989CA9',
              fontSize: 16,
              marginTop: 15,
            
            }}>
              Description: <span style={{ color: 'black' }}>{`${instructor.profile.description}`}</span>
            </div>
          </div>
        </div>
        <div className={styles.UserGeneralInfo__setting}>
          <Popconfirm
            title={'Are you sure?'}
            icon={<QuestionCircleOutlined />}
            onConfirm={() => BlockUserState(instructor.id, instructor.blocked)}
          >
            {instructor.blocked ? <Button style={{
              borderRadius: 4,
              backgroundColor: 'green',
              marginRight: 10
            }} type="primary">Activate user</Button> :
              <Button style={{
                borderRadius: 4,
                backgroundColor: 'red',
                marginRight: 10
              }} type="primary">Block user</Button>}
          </Popconfirm>

          <Button style={{ borderRadius: 4 }} type="primary" onClick={() => handleEditProfile(instructor)}>Edit Profile</Button>
        </div>
      </div>
      <div className={styles.personalInfo__classes}>
        <span className={styles.personalInfo__classes__title}>Classes:</span>
        {instructor && instructor.profile && instructor.profile.classes.length > 0 ?
          <div style={{ marginTop: 5 }}>
            {instructor && instructor.profile && instructor.profile.classes && instructor.profile.classes.map((e: string) =>
              <Tag className={styles.customTag} key={`${e}${Math.random()}`}>{e}</Tag>
            )}
          </div> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

      </div>
      <Divider />
      <div className={styles.personalInfo__certeficates}>
        <span className={styles.personalInfo__classes__title}>Certificates tag and photo:</span>
        {instructor.profile.certificates.length > 0 || instructor.profile.certificateFiles.length > 0 ?
          <div style={{ marginTop: 5 }}>
            {instructor && instructor.profile && instructor.profile.certificates && instructor.profile.certificates.map((e: string) =>
              <Tag className={styles.customTag} key={`${e}${Math.random()}`}>{e}</Tag>
            )}
            <div style={{ marginTop: 24 }}>
              {instructor.profile.certificateFiles && instructor.profile.certificateFiles.map((e: string) =>
                <Image key={e}
                  width={150}
                  src={`${e}`}
                />
              )}
            </div>
          </div> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }

      </div>
      <Divider />
      <div className={styles.personalInfo__gallery}>
        <span className={styles.personalInfo__classes__title}>Photos:</span>
        {instructor && instructor.profile && instructor.profile.photos.length > 0 ?
          <Space size={'small'} style={{ marginTop: 24, display: 'flex', flexWrap: 'wrap' }}>
            {instructor.profile.photos && instructor.profile.photos.map((e: string) =>
              <Image key={e}
                width={150}
                src={`${e}`}
              />
            )}
          </Space > : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }

      </div>
      <div className={styles.personalInfo__CreationDate}>
        Сreation date: {moment(Number(instructor.profile.createdAt)).format("L h:mm A")}
      </div>

    </div> : <div></div>
  )

}

export default InstructorPersonalInfo