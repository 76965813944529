import { Popover } from "antd";
import { useEffect, useState } from "react";
import { IRequest, PaymentStatus } from "../../interfaces/IPayment";

interface Props {
    record: IRequest;
}

export const TablePaymentStatus = (props: Props) => {
    const { record } = props;
    const [paymentStatus, setPaymentStatus] = useState<string>();
    const [popoverTitle, setPopoverTitle] = useState<string>();
    const [statusColor, setStatusColor] = useState<string>();

    useEffect(() => {
        if (record.status === PaymentStatus.APPROVED && record.transferId && record.paymentId) {
            setPaymentStatus('Transfered');
            setStatusColor('green');
            setPopoverTitle('✅ We got this payment and transfer to the instructor')
        };
        if (record.status === PaymentStatus.APPROVED && !record.transferId && record.paymentId) {
            setPaymentStatus('Success');
            setStatusColor('#14afe3');
            setPopoverTitle('✅  We got this payment to our account')
        };
        if (record.status === PaymentStatus.HOLD || record.status === PaymentStatus.NEW) {
            setPaymentStatus('Pending');
            setStatusColor('#ffc107');
            setPopoverTitle("⚠️ We got this payment to our account, but the instructor must to accept or reject user's request")
        };
        if (record.status === PaymentStatus.REJECTED && record.paymentId) {
            setPaymentStatus('Rejected');
            setStatusColor('red');
            setPopoverTitle('❌ The instructor rejected this request for class')
        };
        if (record && record.credits && record.credits > 0) {
            setPaymentStatus('Success');
            setStatusColor('green');
            setPopoverTitle('✅ Customer used credits for this payment')
        };
        if (record && record.credits && record.credits > 0 && record.status === PaymentStatus.NEW) {
            setPaymentStatus('Pending');
            setStatusColor('#ffc107');
            setPopoverTitle("⚠️ We got this payment to our account, but the instructor must to accept or reject user's request")
        };
    }, [record]);

    return (
        <Popover title={popoverTitle}>
            <div style={{ color: statusColor }}>{paymentStatus}</div>
        </Popover>
    )
}