import React, { useState, createContext } from 'react';

interface Context {
	visible: boolean,
	setVisible: React.Dispatch<React.SetStateAction<boolean>>;
	update: boolean,
	setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
	defaultFields: any;
	setDefaultFields: React.Dispatch<React.SetStateAction<any>>;
	id: string;
	setId: React.Dispatch<React.SetStateAction<string>>;
	search: string;
	setSearch: React.Dispatch<React.SetStateAction<string>>;
	responseObjName: string | undefined;
	setResponseObj: React.Dispatch<React.SetStateAction<string | undefined>>;
	defaultProfileFields:any;
	setDefaultProfileFields:React.Dispatch<React.SetStateAction<any>>;
	profileID:string;
	setProfileID:React.Dispatch<React.SetStateAction<string>>;
}

const DataTableContext = createContext<Context>({} as Context);

const DataTableProvider: React.FC<{ value: string | undefined }> = (props) => {

	const [visible, setVisible] = useState(false);
	const [update, setUpdate] = useState(false);
	const [defaultFields, setDefaultFields] = useState({});
	const [id, setId] = useState('');
	const [search, setSearch] = useState('');
	const [responseObjName, setResponseObj] = useState(props.value);
	const [defaultProfileFields, setDefaultProfileFields] = useState({})
	const [profileID, setProfileID] = useState('')

	return (
		<DataTableContext.Provider value={{
			visible,
			setVisible,
			update,
			setUpdate,
			defaultFields,
			setDefaultFields,
			id,
			setId,
			search,
			setSearch,
			responseObjName,
			setResponseObj,
			defaultProfileFields,
			setDefaultProfileFields,
			profileID,
			setProfileID
		}}>
			{props.children}
		</DataTableContext.Provider>
	);
}

export { DataTableContext, DataTableProvider };