import { useEffect, useContext, useState } from 'react';
import { Modal, Button, Form, Input, message, Upload, Checkbox, Select, Space, Card, InputNumber, Divider } from 'antd';
import { DataTableContext } from '../../context/DataTableContext';
import styles from './formModal.module.css';
import {
  CloudUploadOutlined,
  MinusCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { FormFields } from '../PageData';
import MediaLibrary from '../Library';
import { useParams } from 'react-router-dom';
import { TagsInput } from './TagsInput';
import React from 'react';
import { priceDivision } from '../../pages/Payments/Payments';
const { Option } = Select;
const { TextArea } = Input;

interface Props {
  formFields?: FormFields[];
  refetch: any;
  useCreateMutation?: any;
  title: string;
  useUpdateMutation: any;
  customRefetch?: any;
}
enum ObjTypes {
  users = 'users',
  classes = 'classes',
  profile = 'profile',
};

export enum FieldTypes {
  CHECKBOX,
  IMAGE,
  FILE,
  INPUT,
  SELECT_MULTIPLE,
  SELECT,
  TEXTAREA,
  MULTIPLE_FIELDS,
  VIDEO,
  TAGS,
  IMAGES,
  INPUT_NUMBER,
};

const FormModal = ({ formFields, refetch, useUpdateMutation, title, customRefetch }: Props) => {
  const params: any = useParams();
  const { visible, setVisible, update, setUpdate, defaultFields, setDefaultFields, setId, id, responseObjName, profileID, defaultProfileFields, setDefaultProfileFields, setProfileID } = useContext(DataTableContext);
  const [updateMutation, { loading: updateLoading, data: updateData }] = useUpdateMutation();
  const [imageBase64Value, setImageBase64Value] = useState<{ data: string, field: string }[]>([]);
  const [fileLink, setFileLink] = useState<{ data: string, field: string }[]>([]);
  const [mediaModalVisible, setMediaModalVisible] = useState(false);
  const [mediaModalFieldName, setMediaModalFieldName] = useState('');
  const [mediaModalFieldIndex, setMediaModalFieldIndex] = useState(0);
  const [mediaModalNestedField, setMediaModalNestedField] = useState('');
  const [form] = Form.useForm();
  const [files, setFiles] = useState<any>({});
  const [tagsData, setTagsData] = useState<any>()
  const [tagsImageFieldValues, setTagsImageFieldValues] = useState<any>({})


  const onFinish = async (values: any) => {

    try {
      switch (responseObjName) {
        case ObjTypes.users:
          const credits = values.credits && values.credits > 0 ? values.credits.toFixed(1) : 0
          const variables: any = {
            ...values, credits: credits && credits > 0 ? Math.round(credits * priceDivision) : 0
          };
          if (update && id) {
            variables.id = id;
          };
          if (imageBase64Value.length > 0) {
            imageBase64Value.forEach(item => variables[item.field] = item.data)
          };
          await updateMutation({
            variables
          });
          break;

        case ObjTypes.classes:
          const classVariables: any = {
            ...values
          };
          if (update && id) {
            classVariables.id = id;
          };

          if (imageBase64Value.length > 0) {
            imageBase64Value.forEach(item => classVariables[item.field] = item.data)
          };
          const data = JSON.stringify({ ...classVariables, instructor: { _id: params.id } });
          await updateMutation({
            variables: { data },
          });
          break;

        case ObjTypes.profile:
          const userInfo = {
            name: values.name,
            email: values.email,
            id: id
          };
          delete values.name;
          delete values.email;
          const profileInfo = {
            ...values,
            id: profileID
          };
          for (const [key, value] of Object.entries(tagsData)) {
            profileInfo[key] = value
          };
          await updateMutation({
            variables: {
              userArgs: JSON.stringify(userInfo),
              profileArgs: JSON.stringify(profileInfo)
            },
          });
          break;

      };
    } catch (error: any) {
      errorMessage(error.message)
    }
  };

  useEffect(() => {
    if (updateData) {
      successMessage(update ? 'Successfully updated' : 'Successfully added');
      handleCancel();
      refetch();
      customRefetch && customRefetch();
    }
  }, [updateData]);

  useEffect(() => {
    if (defaultProfileFields) {
      const allFields = { ...defaultProfileFields, ...defaultFields }
      for (const [key, value] of Object.entries(allFields)) {

        if (Array.isArray(value)) {
          // const field = formFields && formFields.find(field => field.name === key);
          const filteredValues = value.map(v => {
            // console.log(v);
            // const asArray = Object.entries(v)
            // .filter(([arrayKey, arrayValue]) => field && field.nestedFields && field.nestedFields.some(nf => nf.name === arrayKey));
            // return Object.fromEntries(asArray);
            return v
          });
          if (filteredValues) {

            form.setFieldsValue({
              [key]: filteredValues
            });
          }
        } else {
          form.setFieldsValue({
            [key]: value
          });
        }

      };
      setTagsImageFieldValues({ ...allFields });
    } else {

      for (const [key, value] of Object.entries(defaultFields)) {
        if (Array.isArray(value)) {
          // const field = formFields && formFields.find(field => field.name === key);
          const filteredValues = value.map(v => {
            // console.log(v);
            // const asArray = Object.entries(v)
            // .filter(([arrayKey, arrayValue]) => field && field.nestedFields && field.nestedFields.some(nf => nf.name === arrayKey));
            // return Object.fromEntries(asArray);
            return v
          });
          if (filteredValues) {
            form.setFieldsValue({
              [key]: filteredValues
            });
          }
        } else {
          form.setFieldsValue({
            [key]: value
          });
        }
      };
      setTagsImageFieldValues({ ...defaultFields });
    }

  }, [defaultFields, form, formFields, defaultProfileFields]);


  const errorMessage = (text: string) => {
    message.error(text);
  };

  const successMessage = (text: string) => {
    message.success(text);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleCancel = () => {
    setVisible(false);
    setDefaultFields({});
    form.resetFields();
    setUpdate(false);
    setId('');
    setImageBase64Value([]);
    setFileLink([]);
    setFiles({});
    setDefaultProfileFields(null);
    setProfileID('');
    setTagsImageFieldValues({});
    setTagsData({});
  };

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 50;
    if (!isLt2M) {
      message.error('Image must be smaller than 50MB!');
    }
    return isJpgOrPng && isLt2M;
  }
  // const beforeUploadVideo = (file: any) => {
  //   const isVideo = file.type.include('video')

  //   if (!isVideo) {
  //     message.error('You can only upload video file!');
  //   }
  //   const isLt2M = file.size / 1024 / 1024 < 50;
  //   if (!isLt2M) {
  //     message.error('Image must be smaller than 50MB!');
  //   }
  //   return isVideo && isLt2M;
  // }

  const getBase64Value = (
    img: File | Blob,
    callback: (imageBase64Value: string) => void
  ) => {
    const reader = new FileReader();
    reader.readAsDataURL(img);
    reader.onload = () => {
      callback(reader.result as string);
    };
  };

  const handleMultipleSelectChange = async (info: any, fieldName: string) => { }

  const handleChange = async (info: any, fieldName: string) => {
    setFiles({
      ...files,
      [fieldName]: null
    });
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === "done" && info.file.originFileObj) {
      getBase64Value(info.file.originFileObj, imageBase64 => {
        const index = imageBase64Value.map(function (e) { return e.field; }).indexOf(fieldName);
        if (index !== -1) {
          const images = [...imageBase64Value];
          images.splice(index, 1);
          setImageBase64Value([
            ...images,
            {
              data: imageBase64,
              field: fieldName
            }
          ])
        } else {
          setImageBase64Value([
            ...imageBase64Value,
            {
              data: imageBase64,
              field: fieldName
            }
          ]);
        }
      });
    }
  };

  const handleChangeFile = async (info: any, fieldName: string, index?: number, nestedFieldName?: string) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    fileList = fileList.map(file => {
      console.log(file)
      if (file.response) {
        file.url = file.response.data[0];
      }
      return file;
    });
    if (typeof index === 'number' && nestedFieldName) {
      const currentValues = form.getFieldsValue([fieldName]);
      currentValues[fieldName][index] = {
        ...currentValues[fieldName][index],
        [nestedFieldName]: info.file.response && info.file.response.data[0]
      }
      form.setFieldsValue({
        [fieldName]: currentValues[fieldName]
      });
      const currentFiles = { ...files };
      currentFiles[fieldName] = currentFiles[fieldName] ? currentFiles[fieldName] : [];
      currentFiles[fieldName][index] = {
        [nestedFieldName]: fileList
      }
      setFiles(currentFiles);
    } else {
      if (fileList && fileList[0] && fileList[0].url) {
        form.setFieldsValue({
          [fieldName]: fileList[0].url
        });
      } else {
        form.setFieldsValue({
          [fieldName]: null
        });
      }
      setFiles({
        ...files,
        [fieldName]: fileList
      });
    }
  }

  // const handleChangeFile = async (info: any, fieldName: string) => {
  //   if (info.file.status === 'uploading') {
  //     return;
  //   }
  //   if (info.file.status === "done" && info.file.response.data) {
  //     const index = fileLink.map(function(e) { return e.field; }).indexOf(fieldName);        
  //     if(index !== -1) {
  //       const files = [...fileLink];
  //       files.splice(index,1);
  //       setFileLink([
  //         ...files,
  //         {
  //           data: info.file.response.data[0],
  //           field: fieldName
  //         }
  //       ])
  //     } else {
  //       setFileLink([
  //         ...fileLink,
  //         {
  //           data: info.file.response.data[0],
  //           field: fieldName
  //         }
  //       ]);
  //     }
  //   }
  // };

  const mediaModalVisibility = (value: boolean) => {
    setMediaModalVisible(value);
  }

  const onFileSelectHandler = (file?: string) => {
    if (mediaModalNestedField) {
      const currentValues = form.getFieldsValue([mediaModalFieldName]);
      currentValues[mediaModalFieldName][mediaModalFieldIndex] = {
        ...currentValues[mediaModalFieldName][mediaModalFieldIndex],
        [mediaModalNestedField]: file
      }
      form.setFieldsValue({
        [mediaModalFieldName]: currentValues[mediaModalFieldName]
      });
      const currentFiles = { ...files };
      currentFiles[mediaModalFieldName] = currentFiles[mediaModalFieldName] ? currentFiles[mediaModalFieldName] : [];
      currentFiles[mediaModalFieldName][mediaModalFieldIndex] = {
        [mediaModalNestedField]: [{
          uid: '-1',
          name: 'Uploaded file',
          status: 'done',
          url: file,
        }]
      }
      setFiles(currentFiles);
    } else {
      form.setFieldsValue({
        [mediaModalFieldName]: file
      });
      setFiles({
        ...files,
        [mediaModalFieldName]: [{
          uid: '-1',
          name: 'Uploaded file',
          status: 'done',
          url: file,
        }],
      });
    }
    setMediaModalVisible(false);
    setMediaModalFieldName('');
    setMediaModalFieldIndex(0);
    setMediaModalNestedField('');
    const index = imageBase64Value.map(function (e) { return e.field; }).indexOf(mediaModalFieldName);
    if (index !== -1) {
      const images = [...imageBase64Value];
      images.splice(index, 1);
      setImageBase64Value([
        ...images
      ])
    }
  };

  const handleFormTags = (tags: string[], fieldName: string) => {
    setTagsData({ ...tagsData, [fieldName]: tags })
  };

  const handleChangeImageFiles = async (info: any, fieldName: string) => {
    let fileList = [...info.fileList];
    fileList = fileList.map(file => {
      console.log(file)
      if (file.response) {
        file.url = file.response.data[0];
      };
      return file.url;
    });
    form.setFieldsValue({
      [fieldName]: fileList
    });
  };

  return (
    <>
      <Modal
        width="100%"
        style={{ top: 10 }}
        visible={mediaModalVisible}
        onCancel={() => mediaModalVisibility(false)}
        footer={null}
        zIndex={1010}
      >
        <MediaLibrary modal={true} onFileSelect={onFileSelectHandler} />
      </Modal>
      <Modal
        style={{ borderRadius: 8, paddingBottom: 0 }}
        destroyOnClose={true}
        title={`${update ? 'Edit' : 'Add'} ${title}`}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={form}
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >

          {formFields && formFields.map((field: any, index: number) => {
            switch (field.type) {
              case FieldTypes.IMAGE:
                const image = imageBase64Value.find(item => item.field === field.name) || files[field.name] && files[field.name][0].url || defaultFields[field.name];
                return (
                  <Form.Item
                    label={field.label}
                    name={field.name}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    getValueFromEvent={({ file }) => file.originFileObj}
                    key={index}
                  // valuePropName="fileList"
                  >
                    <Upload
                      accept="image/png, image/jpeg"
                      maxCount={1}
                      listType="picture-card"
                      showUploadList={false}
                      customRequest={dummyRequest}
                      className={field.bigImage ? 'big' : 'small'}
                      beforeUpload={beforeUpload}
                      onChange={(e) => handleChange(e, field.name)}
                    >
                      {image ?
                        <img src={image.data || image} alt="avatar" style={{ width: '100%' }} /> :
                        <div className={field.bigImage ? styles.button : ''}><CloudUploadOutlined className={styles.icon} />Upload</div>
                      }
                    </Upload>
                    <span className={styles.or}>or</span>
                    <Button type="link" onClick={() => {
                      setMediaModalFieldName(field.name);
                      mediaModalVisibility(true);
                    }}> Choose from library</Button>
                  </Form.Item>
                );
              case FieldTypes.IMAGES:
                const fileList: any = []
                tagsImageFieldValues[field.name] && tagsImageFieldValues[field.name].forEach((e: any) => {
                  fileList.push({
                    uid: e,
                    name: e,
                    status: 'done',
                    url: e
                  })
                })
                return (
                  <Form.Item
                    label={field.label}
                    name={field.name}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    getValueFromEvent={({ file }) => file.originFileObj}
                    key={index}>
                    <Upload
                      className={styles.customUpload}
                      accept="image/png, image/jpeg"
                      name="files"
                      action="/api/upload"
                      showUploadList={true}
                      defaultFileList={fileList}
                      listType="picture"
                      fileList={files[field.name]}
                      onChange={(e) => handleChangeImageFiles(e, field.name)}
                    >
                      <Button className={styles.customUploadBtn}>Click to Upload</Button>
                    </Upload>
                  </Form.Item>
                );
              case FieldTypes.FILE:
                return (
                  <Form.Item
                    label={field.label}
                    key={index}
                    name={field.name}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    getValueFromEvent={({ file }) => file.originFileObj}
                  // valuePropName="fileList"
                  >
                    <Upload
                      name="files"
                      action="/api/upload"
                      maxCount={1}
                      showUploadList={true}
                      defaultFileList={defaultFields[field.name] && [{
                        uid: '1',
                        name: "Uploaded file",
                        status: 'done',
                        url: defaultFields[field.name],
                      },]}
                      fileList={files[field.name]}
                      onChange={(e) => handleChangeFile(e, field.name)}
                    >
                      <Button icon={<CloudUploadOutlined />}>Click to Upload</Button>
                    </Upload>
                    <span className={styles.or}>or</span>
                    <Button type="link" onClick={() => {
                      setMediaModalFieldName(field.name);
                      mediaModalVisibility(true);
                    }}> Choose from library</Button>
                  </Form.Item>
                );
              case FieldTypes.VIDEO:
                return (
                  <Form.Item
                    label={field.label}
                    key={index}
                    name={field.name}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    getValueFromEvent={({ file }) => file.originFileObj}
                  // valuePropName="fileList"
                  >
                    <Upload
                      accept={"video/mp4,video/x-m4v,video/*"}
                      name="files"
                      action="/api/upload"
                      maxCount={1}
                      showUploadList={true}
                      defaultFileList={defaultFields[field.name] && [{
                        uid: '1',
                        name: "Uploaded file",
                        status: 'done',
                        url: defaultFields[field.name],
                      },]}

                      fileList={files[field.name]}
                      onChange={(e) => handleChangeFile(e, field.name)}
                    >
                      <Button icon={<CloudUploadOutlined />}>Click to Upload</Button>
                    </Upload>
                    <span className={styles.or}>or</span>
                    <Button type="link" onClick={() => {
                      setMediaModalFieldName(field.name);
                      mediaModalVisibility(true);
                    }}> Choose from library</Button>
                  </Form.Item>
                );
              case FieldTypes.CHECKBOX:
                return (
                  <Form.Item
                    label={field.label}
                    name={field.name}
                    rules={field.rules}
                    key={index}
                    valuePropName='checked'
                  >
                    <Checkbox />
                  </Form.Item>
                );
              case FieldTypes.INPUT:
                return (
                  <Form.Item
                    label={field.label}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name={field.name}
                    rules={field.rules}
                    key={index}
                  >
                    <Input size="large" style={{ color: '#101010', fontSize: 14, fontWeight: 500 }} />
                  </Form.Item>
                );
              case FieldTypes.INPUT_NUMBER:
                return (
                  <Form.Item
                    label={field.label}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name={field.name}
                    rules={field.rules}
                    key={index}
                  >
                    <InputNumber min={0} size="large" style={{ width: '100%' }} />
                  </Form.Item>
                );
              case FieldTypes.SELECT_MULTIPLE:
                return (
                  <Form.Item
                    label={field.label}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name={field.name}
                    rules={field.rules}
                    key={index}
                  >
                    <Select
                      defaultActiveFirstOption={true}
                      mode="multiple"
                      style={{ width: '100%' }}
                      placeholder="Please select"
                      onChange={(e) => handleMultipleSelectChange(e, field.name)}
                    >
                      {field && field.options && field.options.map((option: any) => {
                        return (
                          <Option key={option.value} value={option.value}>{option.name}</Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                );
              case FieldTypes.SELECT:
                return (
                  <Form.Item
                    label={field.label}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name={field.name}
                    rules={field.rules}
                    key={index}
                  >
                    <Select
                      size='large'
                      style={{ width: '100%', fontWeight: 500 }}
                      placeholder="Please select"
                      onChange={(e) => handleMultipleSelectChange(e, field.name)}
                    >
                      {field && field.options && field.options.map((option: any) => {
                        return (
                          <Option key={option.value} value={option.value}>{option.name}</Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                );
              case FieldTypes.TEXTAREA:
                return (
                  <Form.Item
                    label={field.label}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name={field.name}
                    rules={field.rules}
                    key={index}
                  >
                    <TextArea style={{ fontSize: 14 }} rows={3} />
                  </Form.Item>
                );
              case FieldTypes.MULTIPLE_FIELDS:
                return (
                  <Card style={{ marginBottom: 24 }}>
                    <Form.List name={field.name} key={index} >
                      {(fields, { add, remove }) => (
                        <>
                          <div className={styles.label}>{field.label}</div>
                          {fields.map((currentField, index) => (
                            <Space key={currentField.key} align="baseline" wrap>
                              {field.nestedFields.map((nestedField: any) => {
                                const isMuseum = form.getFieldValue([field.name, currentField.fieldKey, 'museum']) === false;
                                switch (nestedField.type) {
                                  case FieldTypes.SELECT:
                                    return (
                                      <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, curValues) =>
                                          prevValues[currentField.name] !== curValues[currentField.name]
                                        }
                                      >
                                        {() => (
                                          <Form.Item
                                            {...currentField}
                                            name={[currentField.name, nestedField.name]}
                                            fieldKey={[currentField.fieldKey, nestedField.name]}
                                            rules={field.rules}
                                          >
                                            <Select
                                              placeholder="Please select"
                                              style={{ width: 200 }}
                                              disabled={isMuseum}
                                            >

                                              {field.options.filter((item: any) => isMuseum ? item : item.museum).map((item: any) => (
                                                <Option key={item.value} value={item.value}>
                                                  {item.name}
                                                </Option>
                                              ))}
                                            </Select>
                                          </Form.Item>
                                        )}
                                      </Form.Item>
                                    );
                                  case FieldTypes.FILE:
                                    return (
                                      <Form.Item
                                        {...currentField}
                                        name={[currentField.name, nestedField.name]}
                                        fieldKey={[currentField.fieldKey, nestedField.name]}
                                        getValueFromEvent={({ file }) => file.originFileObj}
                                      // valuePropName="fileList"
                                      >
                                        <Upload
                                          name="files"
                                          action="/api/upload"
                                          maxCount={1}
                                          showUploadList={true}
                                          defaultFileList={defaultFields[field.name] && defaultFields[field.name][index] && defaultFields[field.name][index].video && [{
                                            uid: '1',
                                            name: "Uploaded file",
                                            status: 'done',
                                            url: defaultFields[field.name][index].video,
                                          },]}
                                          fileList={files[field.name] && files[field.name][index] && files[field.name][index]["video"]}
                                          onChange={(e) => handleChangeFile(e, field.name, currentField.name, "video")}
                                        >
                                          <Button icon={<CloudUploadOutlined />}>Click to Upload</Button>
                                        </Upload>
                                        <span className={styles.or}>or</span>
                                        <Button type="link" onClick={() => {
                                          setMediaModalFieldName(field.name);
                                          setMediaModalFieldIndex(index);
                                          setMediaModalNestedField("video");
                                          mediaModalVisibility(true);
                                        }}> Choose from library</Button>
                                        {/* <a 
                                        target="_blank" 
                                        style={{display: defaultFields[field.name] && defaultFields[field.name][index] ? 'block' : 'none'}} 
                                        href={defaultFields[field.name] && defaultFields[field.name][index] && defaultFields[field.name][index].video}>Uploaded file</a> */}
                                      </Form.Item>
                                    )
                                  default:
                                    return <div></div>
                                }
                              })}
                              <MinusCircleOutlined onClick={() => remove(currentField.name)} />
                            </Space>
                          ))}

                          <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                              {`Add ${field.name}`}
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Card>
                );
              case FieldTypes.TAGS:
                return (
                  <Form.Item
                    label={field.label}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name={field.name}
                    rules={field.rules}
                    key={index}
                  >
                    {tagsImageFieldValues[field.name] &&
                      <TagsInput
                        fieldName={field.name}
                        data={tagsImageFieldValues && tagsImageFieldValues[field.name] && tagsImageFieldValues[field.name]}
                        handleFormTags={handleFormTags} />}

                  </Form.Item>
                )
              default:
                return <div></div>
            }
          })}
          <Divider />
          <Form.Item style={{ textAlign: 'center', }}>
            <div style={{ display: 'flex', justifyContent: "flex-end", width: '100%' }}>
              <Button htmlType="button" className={styles.customCancelBtn} onClick={handleCancel}>
                Cancel
              </Button>
              <Button className={styles.customApproveBtn} type="primary" htmlType="submit" loading={updateLoading}>
                {update ? 'Update' : 'Add'}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default FormModal;