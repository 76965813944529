import React from 'react'
import moment from "moment";
import { useHistory } from "react-router-dom";
import PageData from "../../components/PageData"
import { useRequests } from '../../graphql.querys/requests/__generated__/Request.query'
import styles from './Payments.module.css'
import { TablePaymentStatus } from '../../components/Payment/TablePaymentStatus';
export const priceDivision = 100
export const stripePaymentsDashboardUrl = "https://dashboard.stripe.com/payments"

const Payments = (props: any) => {
    const history = useHistory();

    const handlePathClick = (id: string) => (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        history.push(`/payments/user/${id}`)
    };

    const columns = [

        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            render: (text: any, record: any, index: any) => {
                return (<div >{moment(Number(record.createdAt)).format("L h:mm A")}</div>)
            }
        },
        {
            title: 'User Name',
            dataIndex: 'user',
            key: 'user',
            sorter: true,
            render: (text: any, record: any, index: any) => {
                return (<div onClick={handlePathClick(record.user.id)} className={styles.click}>{record && record.user && record.user.name}</div>)
            }
        },
        {
            title: 'Amount ',
            dataIndex: 'price',
            key: 'price',
            sorter: true,
            render: (text: any, record: any, index: any) => {
                return (<div>${record.price / priceDivision}</div>)
            }
        },
        {
            title: 'Course Style',
            dataIndex: 'class',
            key: 'class',
            width: 200,
            render: (text: any, record: any, index: any) => {
                return (<div>{record && record.class && record.class.style}</div>)
            }
        },
        {
            title: 'Course Instructor',
            dataIndex: 'instructor',
            key: 'instructor',
            sorter: true,
            render: (text: any, record: any, index: any) => {
                return (<div onClick={handlePathClick(record.instructor.id)} className={styles.click}>{record && record.instructor && record.instructor.name}</div>)
            }
        },
        {
            title: 'Course Start Date',
            dataIndex: 'class',
            key: 'class',
            render: (text: any, record: any, index: any) => {
                return (<div>{record && record.class && record.class.startDate && moment(Number(record.class.startDate)).format("L h:mm A")}</div>)
            }
        },
        {
            title: 'Stripe ID',
            dataIndex: 'paymentId',
            key: 'paymentId',
            render: (text: any, record: any, index: any) => {
                return (<a href={`${stripePaymentsDashboardUrl}/${text}`} target={'_blank'} rel={'noreferrer'}>{text}</a>)
            }
        },
        {
            title: 'Payed with credits',
            dataIndex: 'credits',
            key: 'credits',
            render: ((credit: any, record: any, index: any) => {
                return (<div>${credit / priceDivision}</div>)
            })
        },
        {
            title: 'Payment Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            render: (text: any, record: any, index: any) => {
                return (
                    <TablePaymentStatus record={record} />
                )
            }
        },
    ];

    return (
        <PageData
            title="Payments list"
            type={'payments'}
            columns={columns}
            formFields={undefined}
            useGetQuery={useRequests}
            useCreateMutation={undefined}
            useUpdateMutation={undefined}
            useRemoveMutation={undefined}
            modify={false}
            hasDelete={false}
            hasCreate={false}
            hasSearch={true}
            responseObj="requests"
        />
    )
}

export default Payments