import moment from "moment"
import { UserPurchasedCounter } from "../../components/Instructor/UserPurchasedCount"
// import WorkoutActions from "../../components/Instructor/workoutActions/WorkoutActions"
import PageData from "../../components/PageData"
import { useClasses } from '../../graphql.querys/classes/__generated__/classes.query'
import { CheckCircleOutlined, CloseSquareOutlined } from '@ant-design/icons'
import { useRequestsByClassId, useDeletedRequestsByClassId } from '../../graphql.querys/requests/__generated__/Request.query'
import { Badge, Typography } from "antd"
import { getUsernameAvatar } from "../../components/UserAvatar"
import { priceDivision, stripePaymentsDashboardUrl } from "../Payments/Payments"
const { Paragraph } = Typography;

const UserPurchasedcolumns = [
    {
        title: 'Image',
        dataIndex: 'avatar',
        key: 'avatar',
        width: 100,
        render: (src: any, record: any, index: any) => getUsernameAvatar(record.user.name, record.user.avatar),
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text: any, record: any, index: any) => <div>{record.user.name}</div>
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: (text: any, record: any, index: any) => <div>{record.user.email}</div>
    },
    {
        title: 'Stripe ID',
        dataIndex: 'paymentId',
        key: 'paymentId',
        render: (text: any, record: any, index: any) => {
            return (<a href={`${stripePaymentsDashboardUrl}/${text}`} target={'_blank'} rel={'noreferrer'}>{text}</a>)
        }
    },
    {
        title: 'Payed with credits',
        dataIndex: 'credits',
        key: 'credits',
        render: ((credit: any, record: any, index: any) => {
            return (<div>${credit / priceDivision}</div>)
        })
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        render: ((price: any, record: any, index: any) => {
            return (<div>${price / priceDivision}</div>)
        })
    },
    {
        title: 'Payment Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: any, record: any, index: any) => {
            return (<div >{moment(Number(text)).format("L h:mm A")}</div>)
        }
    },
    {
        title: 'User Status',
        dataIndex: 'blocked',
        key: 'blocked',
        width: 100,
        render: (value: any, record: any, index: any) => {
            return (
                record.user.deleted ?
                    <Badge color={'red'} text={'Deleted'} /> :
                    <Badge color={record.user.blocked ? 'red' : 'green'} text={value ? 'Blocked' : 'Active'} />
            )
        },
    },
];
const UsersCanseledClasscolumns = [
    {
        title: 'Image',
        dataIndex: 'avatar',
        key: 'avatar',
        width: 100,
        render: (src: any, record: any, index: any) => getUsernameAvatar(record.user.name, record.user.avatar),
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text: any, record: any, index: any) => <div>{record.user.name}</div>
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: (text: any, record: any, index: any) => <div>{record.user.email}</div>
    },
    {
        title: 'Stripe ID',
        dataIndex: 'paymentId',
        key: 'paymentId',
        render: (text: any, record: any, index: any) => {
            return (<a href={`${stripePaymentsDashboardUrl}/${text}`} target={'_blank'} rel={'noreferrer'}>{text}</a>)
        }
    },
    {
        title: 'Payed with credits',
        dataIndex: 'credits',
        key: 'credits',
        render: ((credit: any, record: any, index: any) => {
            return (<div>${credit / priceDivision}</div>)
        })
    },
    {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        render: ((price: any, record: any, index: any) => {
            return (<div>${price / priceDivision}</div>)
        })
    },
    {
        title: 'Canceled Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text: any, record: any, index: any) => {
            return (<div >{moment(Number(text)).format("L h:mm A")}</div>)
        }
    },
    {
        title: 'User Status',
        dataIndex: 'blocked',
        key: 'blocked',
        width: 100,
        render: (value: any, record: any, index: any) => {
            return (
                record.user.deleted ?
                    <Badge color={'red'} text={'Deleted'} /> :
                    <Badge color={record.user.blocked ? 'red' : 'green'} text={value ? 'Blocked' : 'Active'} />
            )
        },
    },
];

const workoutColums = [

    // {
    //     title: 'Image',
    //     dataIndex: 'promoPhoto',
    //     key: 'promoPhoto',
    //     width: 100,
    //     render: (src: any, record: any, index: any) => getUsernameAvatar(record.style ? record.style : 'Image', src),
    // },
    {
        title: 'Instructor Name',
        dataIndex: 'host',
        key: 'host',
        width: 50,
        sorter: true,
        render: (src: any, record: any, index: any) => {
            return (<div>{record.host.name}</div>)
        },
    },
    {
        title: 'Style',
        dataIndex: 'style',
        key: 'style',
        sorter: true,
        width: 50
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        sorter: true,
        width: 250
    },
    {
        title: 'Online',
        dataIndex: 'online',
        key: 'online',
        width: 50,
        render: (text: any, record: any, index: any) => {
            return (<div >{record.online ? <CheckCircleOutlined style={{ color: "green" }} /> : <CloseSquareOutlined style={{ color: "red" }} />}</div>)
        }
    },
    {
        title: 'Client Room Url',
        dataIndex: 'roomUrl',
        key: 'roomUrl',
        width: 100,
        render: (src: any, record: any, index: any) => {
            return (src ? <Paragraph style={{ color: '#42a0db' }} copyable={{ text: src }}>{src ? 'Client Url' : ''}</Paragraph> : <div></div>)
        }
    },
    {
        title: 'Host Room Url',
        dataIndex: 'hostRoomUrl',
        key: 'hostRoomUrl',
        width: 90,
        render: (src: any, record: any, index: any) => {
            return (src ? <Paragraph style={{ color: '#42a0db' }} copyable={{ text: src }}>{src ? 'Host Url' : ''}</Paragraph> : <div></div>)
        }
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: 250
    },
    {
        title: 'Recurring type',
        dataIndex: 'recurringType',
        key: 'recurringType',
        sorter: true,
        width: 50

    },
    {
        title: 'Users Purchased',
        dataIndex: 'timeSlots',
        key: 'timeSlots',
        width: 50,
        render: (text: any, record: any, index: any) => UserPurchasedCounter(record.id, useRequestsByClassId, "requestsByClassId", UserPurchasedcolumns, "Users Purchased")
    },
    {
        title: 'Refund to users',
        dataIndex: 'timeSlots',
        key: 'timeSlots',
        width: 50,
        render: (text: any, record: any, index: any) => UserPurchasedCounter(record.id, useDeletedRequestsByClassId, "deletedRequestsByClassId", UsersCanseledClasscolumns, 'List of users who made a purchase before canceling a class or time slot')
    },
    {
        title: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        sorter: true,
        width: 50,
        render: (text: any, record: any, index: any) => {
            return (<div >{moment(Number(record.startDate)).format("L h:mm A")}</div>)
        }
    },
    // {
    //     title: 'Actions',
    //     key: 'actions',
    // render: (text: any, record: any, index: any) => (<WorkoutActions refetch={refetch} useRemoveMutation={useRemoveClass} record={record} />),

    // }

]

const Classes = (props: any) => {

    return (
        <PageData
            title="Class list"
            type={'classes'}
            columns={workoutColums}
            formFields={undefined}
            useGetQuery={useClasses}
            useCreateMutation={undefined}
            useUpdateMutation={undefined}
            useRemoveMutation={undefined}
            modify={false}
            hasDelete={false}
            hasCreate={false}
            hasSearch={true}
            responseObj="classes"
        />
    )
}
export default Classes