import * as Types from './types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type UserVariables = Types.Exact<{ [key: string]: never; }>;


export type User = { __typename: 'Query', user: { __typename: 'User', id: string, name?: string | null | undefined } };

export type UserByIdVariables = Types.Exact<{
  id?: Types.Maybe<Types.Scalars['String']>;
}>;


export type UserById = { __typename: 'Query', userById: { __typename: 'User', id: string, name?: string | null | undefined, email: string, avatar?: string | null | undefined, type?: string | null | undefined, blocked?: boolean | null | undefined, deleted?: boolean | null | undefined, createdAt?: string | null | undefined, address?: string | null | undefined, credits?: number | null | undefined } };


export const UserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useUser__
 *
 * To run a query within a React component, call `useUser` and pass it any options that fit your needs.
 * When your component renders, `useUser` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUser({
 *   variables: {
 *   },
 * });
 */
export function useUser(baseOptions?: Apollo.QueryHookOptions<User, UserVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<User, UserVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<User, UserVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<User, UserVariables>(UserDocument, options);
        }
export type UserHookResult = ReturnType<typeof useUser>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<User, UserVariables>;
export const UserByIdDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"userById"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userById"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"avatar"}},{"kind":"Field","name":{"kind":"Name","value":"type"}},{"kind":"Field","name":{"kind":"Name","value":"blocked"}},{"kind":"Field","name":{"kind":"Name","value":"deleted"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"address"}},{"kind":"Field","name":{"kind":"Name","value":"credits"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useUserById__
 *
 * To run a query within a React component, call `useUserById` and pass it any options that fit your needs.
 * When your component renders, `useUserById` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserById({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserById(baseOptions?: Apollo.QueryHookOptions<UserById, UserByIdVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserById, UserByIdVariables>(UserByIdDocument, options);
      }
export function useUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserById, UserByIdVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserById, UserByIdVariables>(UserByIdDocument, options);
        }
export type UserByIdHookResult = ReturnType<typeof useUserById>;
export type UserByIdLazyQueryHookResult = ReturnType<typeof useUserByIdLazyQuery>;
export type UserByIdQueryResult = Apollo.QueryResult<UserById, UserByIdVariables>;