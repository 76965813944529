import * as Types from '../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type FileListVariables = Types.Exact<{
  search?: Types.Maybe<Types.Scalars['String']>;
}>;


export type FileList = { __typename: 'Query', fileList?: Array<{ __typename: 'Object', Key?: string | null | undefined, LastModified?: string | null | undefined } | null | undefined> | null | undefined };


export const FileListDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FileList"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"search"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fileList"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"search"},"value":{"kind":"Variable","name":{"kind":"Name","value":"search"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"Key"}},{"kind":"Field","name":{"kind":"Name","value":"LastModified"}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useFileList__
 *
 * To run a query within a React component, call `useFileList` and pass it any options that fit your needs.
 * When your component renders, `useFileList` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileList({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useFileList(baseOptions?: Apollo.QueryHookOptions<FileList, FileListVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileList, FileListVariables>(FileListDocument, options);
      }
export function useFileListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileList, FileListVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileList, FileListVariables>(FileListDocument, options);
        }
export type FileListHookResult = ReturnType<typeof useFileList>;
export type FileListLazyQueryHookResult = ReturnType<typeof useFileListLazyQuery>;
export type FileListQueryResult = Apollo.QueryResult<FileList, FileListVariables>;