import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu, Popconfirm } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  UserOutlined
} from '@ant-design/icons';
import { DataTableContext } from '../context/DataTableContext';
import { UserTypes } from '../interfaces/User';


export interface Props {
  useRemoveMutation: any;
  refetch: any;
  userType: string
}

function useActionMenu({ useRemoveMutation, refetch, userType }: Props) {
  const { setVisible, setUpdate, setDefaultFields, setId } = useContext(DataTableContext);
  const [removeMutation, { data }] = useRemoveMutation();
  const history = useHistory();

  const handleEdit = (record: any) => {
    setUpdate(true);
    setId(record.id);
    setDefaultFields(record);
    setVisible(true);
  }
  const handleInstructorProfile = (id:string)=> {
    history.push(`/instructors/${id}`);
  }

  const handleSingleDelete = async (record: any) => {
    await removeMutation({
      variables: {
        ids: [record.id]
      }
    })
  };

  useEffect(() => {
    if (data) {
      refetch();
    }
  }, [data]);

  const actionColumnView = (record: any) => (
    <Menu mode="horizontal" style={{ borderBottom: 0, background: "transparent" }}>
      <Menu.Item onClick={() => handleEdit(record)} key="edit" style={{ borderBottom: 0, marginLeft: 0, color: '#57AEA1' }}>
        <EditOutlined />
      </Menu.Item>
      {userType === UserTypes.instructor &&
        <Menu.Item onClick={() => handleInstructorProfile(record.id)} key="profile" style={{ borderBottom: 0, marginLeft: 0, color: '#57AEA1' }}>
          <UserOutlined />
        </Menu.Item>}

      <Menu.Item onClick={(e) => e.domEvent.stopPropagation()} key="delete" style={{ borderBottom: 0, marginLeft: 0, color: '#DF3636' }}>
        <Popconfirm
          title="Sure to delete?"
          placement="left"
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          onConfirm={() => handleSingleDelete(record)}
        >
          <DeleteOutlined type="delete" />
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  return [actionColumnView];
}

export default useActionMenu;