import Header from '../TableHeader/TableHeader';
import useDataTable from '../../hooks/useDataTable';
import FormModal from '../FormModal';
import { Props } from './index';

const DataTable = ({ title, columns, formFields, useGetQuery, useUpdateMutation, useCreateMutation, 
  useRemoveMutation, modify, hasDelete, hasCreate, customRefetch, type }: Props) => {
  
  
  const {
    DataTable,
    hasSelected,
    refetch,
    selectedRowKeys
  } = useDataTable({
    columns,
    useGetQuery,
    useRemoveMutation,
    modify,
    type
  });
  return (
    <>
      {modify && (
        <FormModal 
          formFields={formFields} 
          refetch={refetch}
          customRefetch={customRefetch}
          useCreateMutation={useCreateMutation}
          useUpdateMutation={useUpdateMutation}
          title={title}
        />
      )}
      <Header 
     
        refetch={refetch} 
        useAdd={useCreateMutation} 
        hasSelected={hasSelected} 
        selectedRowKeys={selectedRowKeys}
        title={title} 
        hasDelete={hasDelete} 
        hasCreate={hasCreate} 
        formFields={formFields}
        useRemoveMutation={useRemoveMutation}
        modify={modify}
      />
      <DataTable />
    </>
  )
};

export default DataTable;