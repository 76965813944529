import { Tag, Input, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from 'react';
import 'antd/dist/antd.css';
import styles from './formModal.module.css'

interface Props {
    data: string[]
    fieldName: string
    handleFormTags: (tags: string[], fieldName: string) => void
}
export const TagsInput = (props: Props) => {
    const { data, fieldName, handleFormTags } = props;
    let inputEl = useRef<HTMLElement>(null);
    const [tags, setTags] = useState<any>(data);
    const [inputVisible, setInputVisible] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>();
    const [editInputIndex, setEditInputIndex] = useState<number>(-1);
    const [editInputValue, setEditInputValue] = useState<any>();

    const handleClose = (removedTag: string) => {
        const Tags = tags.filter((tag: any) => tag !== removedTag);
        setTags(Tags)
    };

    const showInput = () => {
        setInputVisible(true)
    };

    useEffect(() => {
        handleFormTags(tags, fieldName)
    }, [tags]);

    useEffect(() => {
        if (inputVisible) {
            inputEl?.current?.focus()
        }
    }, [inputVisible]);

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        setInputValue(e.currentTarget.value)
    };

    const handleInputConfirm = () => {
        let Tags = [...tags]
        if (inputValue && tags.indexOf(inputValue) === -1) {
            Tags = [...tags, inputValue];
        }
        setTags(Tags)
        setInputVisible(false)
        setInputValue('')
    };

    const handleEditInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        setEditInputValue(e.currentTarget.value)
    };
    const handleEditInputConfirm = () => {
        const newTags = [...tags];
        newTags[editInputIndex] = editInputValue;
        setTags(newTags)
        setEditInputIndex(-1)
        setEditInputValue('')
    };

    return (
        <>
            {tags.map((tag: string, index: number) => {
                if (editInputIndex === index) {
                    return (
                        <Input
                            ref={inputEl as React.RefObject<any>}
                            key={tag}
                            size="large"
                            className="tag-input"
                            value={editInputValue}
                            onChange={handleEditInputChange}
                            onBlur={handleEditInputConfirm}
                            onPressEnter={handleEditInputConfirm}
                            style={{ width: 100 }}
                        />
                    );
                }

                const isLongTag = tag.length > 20;

                const tagElem = (
                    <Tag
                        className={styles.customTag}
                        key={tag}
                        closable={true}
                        onClose={() => handleClose(tag)}
                    >
                        <span
                            onDoubleClick={e => {
                                if (index !== 0) {
                                    setEditInputValue(index);
                                    setEditInputValue(tag)
                                    e.preventDefault();
                                }
                            }}
                        >
                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </span>
                    </Tag>
                );
                return isLongTag ? (
                    <Tooltip title={tag} key={tag}>
                        {tagElem}
                    </Tooltip>
                ) : (
                    tagElem
                );
            })}
            {inputVisible && (
                <Input
                    ref={inputEl as React.RefObject<any>}
                    type="text"
                    size="large"
                    className="tag-input"
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                    style={{ width: 130 }}
                />
            )}
            {!inputVisible && (
                <Tag className={styles.customTag} onClick={showInput}>
                    <PlusOutlined /> Add a new one
                </Tag>
            )}
        </>
    );
}