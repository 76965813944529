import { UserOutlined, MailOutlined } from "@ant-design/icons/lib/icons";
import { Avatar, Button, Popconfirm } from "antd";
import { useContext, useEffect } from "react";
import { DataTableContext } from "../../context/DataTableContext";
import FormModal, { FieldTypes } from "../FormModal";
import styles from "./UserProfile.module.css";
import { useUpdateUser } from '../../pages/Users/__generated__/Users.mutation';
import AvatarUploader from "../UserAvatar/AvatarUploader";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import QuestionCircleOutlined from '@ant-design/icons/lib/icons/QuestionCircleOutlined';
import { priceDivision } from "../../pages/Payments/Payments";



interface Props {
    user: any
    refetch: any
}

const formFieldsGeneral = [
    // {
    //     label: "Avatar",
    //     name: "avatar",
    //     type: FieldTypes.IMAGE,
    //     bigImage: false,
    //     image: true,
    //     rules: [
    //         {
    //             required: true,
    //             message: 'Image is required!',
    //         },
    //     ]
    // },
    {
        label: "Name",
        name: "name",
        type: FieldTypes.INPUT,
        rules: [
            {
                required: true,
                message: 'Name is required!',
            },
        ]
    },
    {
        label: "Email",
        name: "email",
        type: FieldTypes.INPUT,
        rules: [
            {
                required: true,
                type: "email",

            },
        ]
    },
    {
        label: "Address",
        name: "address",
        type: FieldTypes.INPUT,

    },

    {
        label: "Credits",
        name: "credits",
        type: FieldTypes.INPUT_NUMBER,
        rules: [
            {
                required: false,
                message: '',
            },

        ]
    },
];

const UserPersonalInfo = (props: Props) => {
    const { user, refetch } = props;
    const { setVisible, setUpdate, setDefaultFields, setId, setResponseObj } = useContext(DataTableContext);
    const [updateMutation, { data }] = useUpdateUser();


    useEffect(() => {
        if (data) {
            refetch()
        }
    }, [data]);

    const handleEditGeneral = (record: any) => {
        setResponseObj('users')
        setUpdate(true);
        setId(record.id);
        setDefaultFields({ ...record, credits: record.credits / priceDivision });
        setVisible(true);
    };
    const BlockUserState = async (userID: string, blocked: any) => {
        await updateMutation({
            variables: {
                id: userID,
                blocked: !blocked
            }
        })
    };

    return (
        <div style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: 42
        }}>
            <FormModal
                formFields={formFieldsGeneral}
                refetch={refetch}
                customRefetch={undefined}
                useCreateMutation={undefined}
                useUpdateMutation={useUpdateUser}
                title={'user profile'}
            />
            <div className={styles.UserGeneralInfo}>
                <div className={styles.UserGeneralInfo__about}>
                    <div className={styles.UserGeneralInfo__about__avatarBox}>
                        <Avatar style={{ backgroundColor: '#F4F5F8' }} src={`${user.avatar}`} size={140} icon={<UserOutlined style={{ color: '#C8CCD9' }} />} />
                        <div className={styles.uploadImage}>
                            <AvatarUploader
                                userID={user.id}
                                useUpdateMutation={useUpdateUser}
                                refetch={refetch} />
                        </div>
                    </div>
                    <div className={styles.UserGeneralInfo__about__infoBox}>
                        <Title level={3}>{user.name}</Title>
                        <div className={styles.infoBox__LinksBox}>
                            <div className={styles.infoBox__LinksBox__link}>
                                <MailOutlined style={{ fontSize: '20px' }} />
                                <a href={`mailto:${user.email}`} target={'_blank'} rel="noreferrer" style={{ marginLeft: 15 }}>{user.email}</a>
                            </div>
                        </div>
                        <div style={{
                            color: '#989CA9',
                            fontSize: 16,
                            marginTop: 15
                        }}>Creddits: <span style={{ color: 'black' }}>${`${user.credits ? user.credits / priceDivision : 0} `}</span>
                        </div>
                        <div style={{
                            color: '#989CA9',
                            fontSize: 16,
                            marginTop: 15
                        }}>
                            Address: <span style={{ color: 'black' }}>{`${user.address ? user.address : 'No data'}`}</span>
                        </div>
                    </div>

                </div>
                <div className={styles.UserGeneralInfo__setting}>
                    <Popconfirm
                        title={'Are you sure?'}
                        icon={<QuestionCircleOutlined />}
                        onConfirm={() => BlockUserState(user.id, user.blocked)}
                    >
                        {user.blocked ? <Button style={{
                            borderRadius: 4,
                            backgroundColor: 'green',
                            marginRight: 10
                        }} type="primary">Activate user</Button> :
                            <Button style={{
                                borderRadius: 4,
                                backgroundColor: 'red',
                                marginRight: 10
                            }} type="primary">Block user</Button>}
                    </Popconfirm>
                    <Button style={{ borderRadius: 4 }} type="primary" onClick={() => handleEditGeneral(user)}>Edit Profile</Button>
                </div>
            </div>
            <div className={styles.personalInfo__CreationDate}>
                Сreation date: {moment(Number(user.createdAt)).format("L h:mm A")}
            </div>
        </div>
    )
};
export default UserPersonalInfo